/* You can add global styles to this file, and also import other style files */

@use "bloomreach";
@import "_variable";
@import "_fonts";
@import "_general";
@import "_components";
@import "_form";
@import "_header";
@import "_home";
@import "_funds";
@import "_fund-details";
@import "_calculators";
@import "_calculator-details";
@import "_knowledge";
@import "_watchlist";
@import "_cart";
@import "_footer-pages";
@import "_investor-services";
@import "_about";
@import "_distributor";
@import "_dark";
@import "_media";
@import "_toaster";
