@import 'ngx-toastr/toastr';

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 15px;
  width: 100%;
  max-width: 420px;
  border-radius: 12px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: none;
  color: #333333;
  font-size: 0.813rem;
  font-weight: 700;
  font-family: "Trade Gothic W01 Roman", Arial, Helvetica, sans-serif;
  background: #FFF;
  box-shadow: 0px 4px 20px 0px rgba(23, 55, 163, 0.16);
  .content {
    text-align: center;
    font-family: "Inter";
    font-size: 0.875rem;;
    font-weight: 600;
    color: #101828;

    img.checked-img {
        width: 40px;
        display: block;
        margin: 0 auto 20px auto
    }

    .btn {
        font-size: 0.875rem;
        padding: 10px 16px;
        font-weight: 500;
        line-height: 20px;
        width: 100%;
        margin-top: 16px;
    }

    .link-box {
        padding: 10px 16px;
        border-radius: 6px;
        border: 1px solid #D2D8EA;
        background: #F0F6FF;
        color: #595A6C;
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: -0.48px;
        overflow: hidden;
        white-space: nowrap;
    }
}

}

.toast-error {
  background: #fae4e6;
  opacity: 1;
  margin-bottom: 5px;
  border: 1px solid white !important;
  border-radius: 4px;
  box-shadow: none;
  -webkit-box-shadow: 0 7px 9px -8px black;
  -moz-box-shadow: 0 7px 9px -8px black;
  background-image: url("/assets/images/exclamation-circle.png") !important;
}

.toast-success {
  background: #fff;
  background-image: url("/assets/images/check-circle.png") !important;
}

button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background:  #B7B7B7;
  border: 0;
  color: white;
  border-radius: 100%;
  top: 12px;
  right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;

  img {
    margin: 0
}
}


@media (min-width: 576px) {
  .toast-container .ngx-toastr {
    width: 300px !important;
  }
}

@media (min-width: 768px) {
  .toast-container .ngx-toastr {
    width: 320px !important;
  }
}

@media (min-width: 992px) {
  .toast-container .ngx-toastr {
    width: 400px !important;
  }
}

@media (max-width: 768px) {
  .toast-container {
    width: 80% !important;
  }
}

@media (min-width: 1200px) {
  .toast-container .ngx-toastr {
    width: 400px !important;
  }
}

.page-dark-mode{
  .toast-container .ngx-toastr {
    background: #000916;
    .content {
      color: #fff;
    }
    .link-box {
      border: 1px solid #0149b1;
      background: #13294b;
      color: #fff;
    }
  }

  button.toast-close-button{
    background: #c1c1c1;
  }

}

