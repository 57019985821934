.page-dark-mode {
  background: var(--dark-body-bg);
  color: #fff;


  .fund-block,
  .solution-box,
  .FT-financial .tab-content,
  .FT-knowledge-tabs .FT-guide .guide-box,
  .creation-box,
  .update-box,
  .FT-fund-types .nav-pills,
  .FT-faq .faq-box,
  .FT-invest-form .form-box {
    background: var(--dark-box-bg);

  }

  .main-content,
  #page-content {
    background-color: #000916 !important;
    color: #fff;

    .text-meduim {
      color: #fff !important;
    }
  }

  .custom-tooltip .tooltiptext {
    background-color: #0C1738 !important;
    color: #fff;
  }

  #page-content.bg-img {
    background: #000916;
    background-image: none;
    color: #fff
  }

  .FT-breadcrumb {
    background-color: #09172D !important;
    color: #fff;
  }

  .FT-fund-statistics .fund-block h3 {
    color: #fff;
  }

  .FT-fund-statistics .fund-block {
    .note {
      color: #fff;
    }

    .fund-num .text-big .text-gray {
      color: #fff;
    }

    .returns-block {
      background-color: #192753;

      .nav-tabs .nav-item .nav-link.active {
        color: #fff !important;
      }

      .return-graph span {
        color: #fff;
      }

      .block-btns .btn.btn-outline-primary {
        background-color: #192753 !important;
      }
    }
  }

  .block-border {
    background: var(--black-Figma);
    color: #fff;

    .block-head h3 {
      color: #fff;
    }

    .block-head h3 small {
      color: #fff;
    }
  }

  .FT-blocks-nav {
    background-color: #13294B;
    color: #fff;
  }

  .FT-blocks-nav ul li a {
    color: #fff;

  }

  // .FT-blocks-nav ul li a.active-item {
  //     background: linear-gradient(90deg, #B9ACFF 26.92%, #5ECBFF 57.79%, #66FFF8 90.66%);
  //     color: #000916 !important;
  // }

  .FT-customized-accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
    color: #fff !important;
  }

  .FT-fund-overview .accordion-item ul li {
    color: #fff;
  }

  .FT-fund-overview .accordion-item .invest-feature {
    color: #fff;
  }

  .FT-customized-accordion .accordion-item .accordion-header .accordion-button {
    color: #fff;
  }

  .FT-performance .graph-block .graph-head .graph-name {
    background: #223B83;
    color: #fff;
  }

  .FT-performance .graph-block .graph-head {
    color: #fff;
  }

  .FT-performance .fund-value-table,
  .FT-fund-more-details {
    .table thead th {
      color: #fff;
      background: #223B83;
    }

    .table {
      tbody>tr:nth-of-type(odd) {
        td {
          background: #09172D;
        }
      }
    }

    .table> :not(caption)>*>* {
      box-shadow: none;
    }

    .table td {
      color: #fff;
      background: #223B83;
    }
  }

  .FT-performance .graph-block .graph-info {
    color: #fff;
  }

  .graph-stat {
    color: #fff !important;
  }

  .FT-fund-more-details .fund-documents .update-box {
    background-color: #09172D;
  }

  .FT-fund-more-details .fund-documents .tab-form .btn {
    // background-color: #232B46;
    color: #fff;
  }

  .FT-fund-more-details .fund-documents .tab-form .form-select {
    background-color: #09172D;
    color: #fff;
  }

  .main-content .nav-tabs .nav-link.active {
    color: var(--blue) !important;
    border-color: var(--blue) !important;
  }

  .FT-fund-more-details .fund-documents .tab-form .date-group .form-control {
    background: #09172D;
    color: #fff;
  }




  .text-teal {
    color: #10BCB3 !important;
  }

  .bg-gradient {
    background: #000916;
    background-image: none !important;
  }

  .FT-performance .performance-head {
    background: linear-gradient(90deg, #B9ACFF 26.92%, #5ECBFF 57.79%, #66FFF8 90.66%) !important;
    color: #000916 !important;
  }

  .FT-fund-more-details .managers.bg-gradient {
    background: linear-gradient(90deg, #B9ACFF 26.92%, #5ECBFF 57.79%, #66FFF8 90.66%) !important;
    color: #000916 !important;
  }

  .FT-fund-more-details .managers {
    .manager-block a {
      color: #000916 !important;
    }
  }

  .btn-outline-primary, .sum-btn-goal {
    background: #112138 !important;
    // color: var(--sky-blue);
    // border: 1px solid var(--sky-blue);
    color: #fff !important;
    border: 1px solid #fff !important;
  }

  .more-links {
    color: #fff;
    font-size: 1.125rem;
    line-height: 101%;
  }

  .FT-solutions {
    .mobile-services-wrapper {
      .services {
        .service {
          .image-wrapper {
            background-color: #0C1738 !important;
            border: 1px solid #fff;
          }

          .description {
            background-color: #0C1738 !important;
            color: #fff !important;
            border: 1px solid #fff;
          }
        }
      }

    }
  }

  .fund-block {

    .tags {
      // background: #112138;
      background: #223B83;

      ul {
        li {
          color: #fff;

          &::after {
            background: #fff;
          }
        }
      }
    }

    .recommend-tag {
      background: #00847D;
      padding: 0 8px;
      color: #fff;
    }

    .dropdown {

      .dropdown-toggle {
        color: #fff;
      }
    }

    .info {
      color: #fff;

      .btn-light-blue {
        background: #0C1738;
        border: 1px solid #304B9B;
        color: #fff;
      }

      .text-big {
        color: #DEDEDE;
      }

      .dropdown {
        .dropdown-menu {
          background-color: #283763;
          color: #fff;

          & li {
            border-bottom: 1px solid #283763;
          }

          a {
            color: #fff;
          }
        }
      }
    }

    .icons {
      .share-menu {
        .dropdown-menu {
          background: #112138;

          li {
            .dropdown-item {
              color: #fff;
            }
          }
        }
      }
    }
  }

  .solution-box {
    background: #0C1738;
    border: 1px solid rgba(210, 216, 234, 0.50);

    h3 {
      color: #fff !important;
    }

    .btn-primary {
      background: #192753 !important;
    }

    &:hover .btn-primary {
      background: #557FFF !important;
    }
  }


  .nav-tabs {
    .nav-link {
      color: var(--sky-blue);
      border-bottom-color: var(--sky-blue);

      &.active {
        color: #fff !important;
        border-bottom-color: #fff !important;
      }
    }
  }

  .slick-slider {
    .slick-arrow {
      background: #09172D;
      color: #fff !important;
    }

  }

  .FT-guide {
    .guide-box {
      background: #192753;

      h3 {
        color: #fff;
      }

      .card-heading {
        color: #FFFFFF;
      }

      .card-subheading {
        color: #FFFFFF;
      }
    }
  }

  form {
    .form-check {
      .form-check-label {
        color: #fff !important;

        a {
          // color: #fff
        }
      }
    }
  }

  .creation-box {
    .img-block {
      background: var(--dark-body-bg);
    }
  }

  .statistics-block {
    color: initial;
  }

  .save-alert {
    .content {
      background: #000916;
      color: #fff;
    }

    .close-btn {
      background: #c1c1c1;
    }

    .link-box {
      border: 1px solid #0149b1;
      background: #13294b;
      color: #fff;
    }
  }




  header {
    .top-menu-block {
      background: #152A4A;

      .user-type {
        li {
          a {
            color: var(--gray) !important;
            // border-color: var(--gray);
          }

          &.active {
            a {
              // color: var(--light-blue) !important
              color: #fff !important;
              border-color: #fff !important;
            }
          }
        }
      }

      .links {
        li {

          a,
          .dropdown .dropdown-toggle {
            color: #fff !important
          }

          .dropdown {
            .dropdown-toggle {
              &::after {
                background-image: url("/assets/images/general/arrow-down-white.svg") !important;
              }
            }

            .dropdown-menu {
              background: #152A4A;
            }
          }
        }
      }
    }

    .more-links-btns .accordion .accordion-item {
      .accordion-header .accordion-button {
        &::after {
          background-image: url("/assets/images/general/Vector.png");
        }

        background: var(--md-dark) !important;
        color: #fff !important;
      }

      .accordion-body {
        background: #223B83;

        a {
          color: #fff !important;
        }

        .dropdown {
          button {
            color: #fff !important;
          }

          .dropdown-menu {
            .block-head {
              color: #fff !important;
            }

            background: #223B83;
          }
        }
      }
    }

    .navbar {
      background: var(--dark-body-bg) !important;

      .navbar-brand {
        img {

          &.dark-logo {
            display: block !important;
          }

          &.light-logo {
            display: none !important;
          }
        }
      }

      .navbar-nav .fill-width .dropdown-menu {
        color: #fff;
        background: #091830;

        .menu-tabs {
          background: #13294b !important;

        }

        .link-box a {
          color: #fff !important
        }
      }

    }
  }

  .calc-box {
    border-radius: 8px;
    border: 2px solid #7F869C;
    background: #192753 !important;
    box-shadow: 0px 4px 20px 0px rgba(23, 55, 163, 0.16);
    color: #fff;

    h4,
    .text-big {
      color: #fff;
    }

    .btn-primary {
      background: rgba(85, 127, 255, 1);

      &:hover {
        // background: #000916;
        background-color: rgb(85, 127, 255);
      }
    }
  }

  .category-card {
    background: #223B83;
    box-shadow: 0px 2px 11.81px 0px rgba(0, 0, 0, 0.10);
    color: #fff;

    .card-body {

      .d-flex,
      .card-icons,
      .card-title {
        color: #fff !important;
      }
    }
  }

  .FT {
    &-title {
      background-image: linear-gradient(89.89deg, #B9ACFF 26.92%, #5ECBFF 57.79%, #66FFF8 90.66%) !important;
    }

    &-locations,
    &-financial,
    &-guide,
    &-latest-updates,
    &-invest-form {
      background: inherit;
    }

    &-updates-banners {
      border: 0;

      .accordion-item {
        .accordion-button {
          background: var(--md-dark) !important;

          .container {
            &::after {
              background: url("/assets/images/home/arrow-down-gradient.svg") no-repeat center;

              background-size: 1.25rem;
            }
          }
        }

        .accordion-body {
          .carousel {

            .carousel-control-next,
            .carousel-control-prev {
              background: #09172D;
              color: #fff;
              &:hover {
                background: var(--blue);
                color: #fff;
              }
            }
          }
        }
      }

    }

    &-hero {
      .page-theme {
        top: 0;

        .font {
          color: #fff;
          background: var(--dark-body-bg);
        }

        .theme-mode {
          border-color: #DDDDDD;

          .dark-mode {
            background: #152A4A;
            color: #fff;

            span {
              background: #09172D;
            }
          }
        }
      }
    }


    &-invest-btns {
      background: var(--md-dark);
      padding-top: 40px;
      padding-bottom: 30px;
    }

    &-locations {
      padding-top: 78px;
    }

    &-fund-types {
      padding-bottom: 70px;

      .nav-pills {
        .nav-link {
          color: #fff;

          &.active {
            color: var(--light-blue);
            border-bottom: 2px solid var(--light-blue);
          }
        }
      }
    }

    &-financial {
      .graph-table-btns {
        background: #223B83;

        .btn.active {
          border: none;
        }
      }

      .tab-content {
        .fund-info {
          background: #223B83;
          color: #fff;

          .btn {
            // background: #192753 !important;
            &:hover{
              background-color: #0d6efd !important;
            }
          }

          h3 {
            color: #fff;

            &.text-teal {
              color: #35E8DE !important
            }
          }
        }
      }
    }

    &-latest-updates {
      background: var(--dark-body-bg);
      padding-bottom: 40px;

    }

    &-faq .faq-box .accordion .accordion-item {
      .accordion-header .accordion-button {
        // color: #fff !important;
        color: #FFFFFF;
      }

      .accordion-body {
        // color: #fff;
        color: #FFFFFF;
      }
    }

    &-invest-form {
      background: var(--dark-body-bg);

      .form-control,
      .form-select {
        // background-color: #09172D;
        // background-color: #203351;
        background-color: #13294b !important;
        border: 0.776786px solid #203351;
        box-shadow: 0px 0.776786px 1.55357px rgba(16, 24, 40, 0.05);
        color: #fff;
        border: 0.776786px solid #fff;

        &::placeholder {
          color: rgba(#fff, 0.5)
        }
      }
    }

    &-download-options {
      background: #000916;
    }

    &-calculator-accordion {
      .accordion {
        .accordion-item {
          .accordion-header {
            button {
              background: #192753 !important;
              color: #fff !important;

              &::after {
                background-image: url("/assets/images/dark/arrow-down-white.svg");
                width: 29px;
                height: 29px;
                background-position: center center;
                background-size: 100%;
              }
            }
          }

          .accordion-body {
            background: #192753 !important;
            color: #fff !important;
            border-color: #7F869C !important;

            &.accordion-slider {
              border: 0 !important;

              .benefits-box {
                background: #192753;

                .text-big {
                  color: #fff;
                }
              }
            }
          }

        }
      }
    }

    &-calculator-box {
      .FT-financial {
        .tab-content.calculator-box {
          border-radius: 8px;
          border: 4px solid #7F869C;
          background: #192753;
          color: #fff;
          box-shadow: 0px 4px 30px 0px rgba(16, 27, 66, 0.15);

          .tab-content {
            background: transparent;
          }

          .graph-table-btns {
            border: 1px solid #CCC;
            background: #223B83;

            .btn {
              &.active {
                background: #192753;
              }
            }
          }

          .invest-options .form-check .form-check-label {
            background: transparent;
            color: #fff;
            border: 1px solid #fff;
          }

          .calculate-statics {
            background: #223B83;
            color: #fff;

            .graph-table-btns {
              border: 1px solid #7F869C;
              background: #192753;

              .btn {
                &.active {
                  background: #223B83;
                }
              }
            }

            .p-3 {
              border-radius: 8px;
              background: #192753;
            }

            .numbers {
              color: #fff;
              background: transparent !important;

              h3 {
                color: #fff;
              }
            }
          }
        }
      }
    }

    &-returns {
      .bg-gradient {
        border-radius: 8px;
        background: linear-gradient(90deg, #583EE7 26.99%, #0EA0E6 50.09%, #00B6AE 74.69%) !important;
      }
    }

    &-solution-planning {
      background-image: none !important;

      .FT-title {
        color: #557FFF !important;
        background-image: #557FFF !important;
        -webkit-text-fill-color: inherit
      }

      .text-big {
        color: #fff !important;

      }

      .goal-cards {
        background: #0C1738 !important;

        .goal-cards-heading {
          color: #fff !important;
        }

        .slick-slide {
          img {
            background: #223B83;
          }
        }

        .solution-box-new .btn {
          background: #223B83;
          color: #fff;
        }
      }

      .planning-card {
        background: #0C1738 !important;
        color: #fff !important;

        .card-header {
          background: transparent !important;
        }
      }
    }

    &-planning .Faq-section .Faq-card,
    &-Faq .Faq-section .Faq-card {
      background: #0C1738 !important;
      color: #fff !important;

      .Faq-card-heading,
      .Faq-card-content {
        color: #fff !important;
      }

      .card {
        background: transparent;
      }
    }

    &-stepper-content {
      background: transparent !important;

      .steps {
        background: #0C1738;

        .step-title {
          color: #fff !important;
        }

        .step-item.active {
          background: #223B83;
          border-color: #fff;

          .step-title {
            color: #fff !important;
          }
        }
      }

      .goal-cards {
        background: #192753;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.06);
        border: 1px solid #C8C8C8;
        color: #fff;

        .stepper-card {
          background: #223B83 !important;

          .image-text {
            color: #fff !important;
          }
        }

        .row-title,
        .range-slider span,
        .footer-text,
        .title {
          color: #fff !important;
        }

        .radio-cards {
          color: #fff;
        }

        .form-control {
          border: 1px solid #FFF;
          background: #09172D;
          box-shadow: 0px 2px 11.81px 0px rgba(201, 201, 201, 0.10);
          color: #fff;

          &::placeholder {
            color: rgba(255, 255, 255, 0.7);
          }
        }
      }
    }

    &-footer-pages {
      color: #fff;

      &.FT-knowledge {
        background: transparent;
      }

      .FT-title-medium.text-blue {
        color: #fff;
      }

      h3 {
        color: #fff;
      }

      .text-big,
      .text-bold {
        color: #fff;
      }

      .general-box {
        color: #fff;
        background: #192753;

        .text-big {
          color: #fff;
        }
      }

      .bg-md-white {
        background: #000916 !important;
        box-shadow: none;
      }

      .features-box,
      .feedback-box,
      .media-contact {
        background: #192753;

        .content {
          color: #fff;
        }

      }

      .media-contact {
        .contact-box {
          background: #223B83;

          h3,
          p {
            color: #fff;
          }
        }
      }

      .knowledge-box {
        background: #192753;
      }

      @media(max-width:767.98px) {
        .features-box .row {
          background: #192753;
        }


        .offcanvas-body {
          background: #000916;
        }

        .makeCardWithCompare {
          background: #000916;
        }

        .cartSipInvestment {
          background: #000916 !important;
        }

        .investmenttData {
          background: #000916 !important;
          color: #fff !important;

          .titlecolor {
            color: #fff !important;
          }
        }

        .forInvestmentCartColor {
          color: #fff !important;
        }

        .addForDivColor {
          background: #000916 !important;

          .cartInteger {
            color: #fff !important;
          }

          .cartText {
            color: #fff !important;
          }

          .cartInvestor {
            color: #fff !important;
          }
        }

        .colorExpandsColorView {
          background: #000916 !important;
        }

        .makeCardFitData {
          background: #0C1738;
          color: #fff !important;
        }

        .addCheck {
          color: #fff !important;
        }

        .checkGoToCheckout .addColorCard {
          background: #0C1738 !important;
          border: 1px solid #D2D8EA;
          border-radius: 8px;
        }

        .makeAddCartData {
          background: #000916 !important;
        }

        .forOtherCardColor {
          .addTitleCart {
            //    color: #fff!important;
          }

          .addValueData {
            color: #fff !important;
          }

          .search-result .inner {
            background: #09172D !important;
          }

          .search-result .sub .text-gray {
            color: #ffff !important;
          }

          .fund-search-filter-title {
            color: #ffff !important;
          }

        }
      }

      .nav-pills {
        background: #223B83;

        .nav-link {
          color: #fff;
          border-color: #fff;
        }
      }

      .feedback-box {
        color: #fff;

        .form-label {
          color: #fff;
        }

        .form-control {
          background: rgba(255, 255, 255, 0.15);
          border-color: #7F869C;
        }
      }
    }
  }

  footer {
    background: #011026 !important;
  }

  .form-select {
    --bs-form-select-bg-img: url("/assets/images/general/arrow-down-white.svg");
    background-size: 13px auto;
    // background-color: #0C1738;
    background-color: #13294b !important;
    color: #FFFFFF;
  }

  .modal .modal-body form .form-control,
  .modal .modal-body form .form-select {
    background-color: #13294b;
    color: #fff;

    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .modal-content {
    background-color: #13294b;
  }

  .FT-hero .carousel .carousel-control-prev,
  .carousel-control-next {
    .banner {
      background: #09172D !important;
      color: white !important;
      &:hover {
        background: var(--blue);
        color: #fff;
      }
    }
  }

  @media(max-width:600px) {
    .fund-block {
      .btn-outline-primary {
        background: #0C1738 !important;
        // color: var(--sky-blue);
        // border: 1px solid var(--sky-blue);
        color: #fff;
        border: 1px solid #304B9B;
      }
    }

    .FT-faq .faq-box .accordion .accordion-item {
      // background: #0C1738 !important;
      background: #13294b !important;


      .accordion-body {
        color: #fff;
        // background: #0C1738;
      }

      .faq-box .accordion .accordion-item {
        .accordion-header .accordion-button {
          // background: #0C1738;
          color: #fff !important;
          // color: #101828!important;
        }

        .accordion-body {
          color: #fff;
          // color: #101828;
        }
      }
    }

    .block-border .block-head .nav-tabs {
      background: #0C1738;
    }

    .FT-performance .performance-head ul li {
      .bg-white .form-group .form-control {
        background: #223B83 !important;
        color: #fff;
      }

      .date-group .form-control {
        background: #223B83;
        color: #fff;
      }
    }

    .FT-performance {
      .performance-head {
        background: #192753 !important;
      }

      .left-items li {
        color: #fff !important;

        .bg-white {
          background: #223B83 !important;
          color: #FFFFFF !important;
        }

        .date-group {
          background: #223B83;

          ::placeholder {
            color: #FFFFFF !important;
          }
        }
      }

      .table thead th {
        font-weight: 500 !important;
      }
    }

    .FT-fund-more-details .fund-documents {
      .nav-tabs {
        background: #0C1738;
      }

      .tab-form .date-group {
        background: #223B83;

        ::placeholder {
          color: #FFFFFF !important;
        }

        .form-control {
          background: #223B83;

          ::placeholder {
            color: #FFFFFF !important;
          }
        }

        .popover {
          background: #223B83;
        }
      }
    }

    .nav-tabs {
      .nav-link {
        color: #8189A2 !important;
      }
    }

    .block-border .block-head h3 small {
      color: #FFFFFF;
    }

    .FT-blocks-nav ul li a.active-item {
      background: linear-gradient(90deg, #B9ACFF 26.92%, #5ECBFF 57.79%, #66FFF8 90.66%);
      color: #000916 !important;
    }

    .FT-fund-more-details .managers.bg-gradient {
      background: linear-gradient(90deg, #B9ACFF 26.92%, #5ECBFF 57.79%, #66FFF8 90.66%) !important;
      color: #000916 !important;
    }

    .FT-fund-more-details .managers {
      .manager-block a {
        color: #000916 !important;
      }
    }

    .FT-all-funds {
      background: #000916 !important;

      .hedaerTitle {
        background: #000916 !important;
      }

      .filters .filter-head {
        background: #223B83;
        color: inherit;
        padding: 10px;
      }

      .filters .filter-container {
        background: #0C1738;
      }

      .filter-options {
        // background: #0C1738 !important;

        .form-group .form-control {
          // background: #0C1738 !important;
          color: #8189A2 !important;
          border: 1px solid #7F869C;
        }
      }

      .filter-options .btn {
        background: #0C1738 !important;
        color: #FFFFFF !important;
        border: 1px solid #7F869C;
      ;
      }

      .filter-selected-options .option {
        background: #0C1738 !important;
        border: 1px solid #7F869C !important;
        color: #FFFFFF !important;
      }
    }

    div.filter-selected-options .option.selected-option1 {
      color: #FFFFFF !important;
      background: #3769FF !important;
      border: 1px solid #3769FF !important;
    }

    .FT-general-filter.cart-aside .filter-box {
      .filter-content {
        background: #0C1738;
        color: #FFF;

        .nav-tabs {
          background: #192753;

          .nav-link.active {
            color: #FFF !important;
          }
        }

        .cart-box {
          background: #192753 !important;

          .text-big {
            color: #FFF !important;
          }

          .price-input {
            background: #09172D;

            .form-control {
              background: #09172D;
              color: #FFF;
            }
          }

          .custom-check.form-check .form-check-label {
            background: #09172D;
            color: #FFF;
            border: 1px solid #D2D8EA;
          }

          .form-group {
            background: #09172D;

            .form-control {
              background: #09172D;
              color: #FFF;
            }
          }
        }
      }

      .forTitle {
        color: #FFF;
      }

      .filter-footer {
        background: #0C1738;
      }
    }

    .FT-general-filter.show .filter-box {
      background: #0C1738;
    }

    .watchlist-funds .tab-head {
      background: #000916;

      .nav-tabs {
        background: #09172d;
      }
    }

    .FT-knowledge-tabs {
      .container {
        background: #0C1738;
      }

      .tab-content {
        color: #fff;

        h3 {
          color: #fff !important;
        }
      }
    }

    .titleAdd {
      color: #fff !important;
    }

    .forCartDataLinearGradient .checkAdded1 {
      color: #fff !important;
    }

    .FT-comparefund-wrapper table>tr:first-child>th:first-child {
      background-color: #13294B !important;
    }

    .FT-comparefund-wrapper table>tr:first-child {
      background: #13294B !important;
    }

    .FT-comparefund-wrapper table>tr:nth-last-child(odd):not(:first-child):not(:nth-child(2)) td {
      background-color: #000916 !important;
    }

    .FT-comparefund-wrapper table>tr:nth-last-child(even):not(:first-child):not(:nth-child(2)) td {
      background-color: #0C1738 !important;
    }

    .FT-comparefund-wrapper table>tr:first-child>th .fund-card-wrap {
      background-color: #000916 !important;
    }

    .FT-comparefund-heading {
      background: #000916 !important;
    }

    .makeCompareMutualColor {
      background: #000916 !important;
    }

    .FT-small-menu .accordion-item .accordion-body .dropdown .dropdown-menu.show {
      background: #000916;
    }

    .FT-small-menu .accordion-item {
      border: 0;
      border-radius: 4px;
      margin-bottom: 12px;
      --bs-accordion-btn-icon: url("/assets/images/general/material-symbols_keyboard3.svg");
      --bs-accordion-btn-icon-width: 24px;

      .accordion-button {
        border-radius: 4px;
        outline: none;
        box-shadow: none;
        font-weight: 700;

        &:not(.collapsed) {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;

          &::after {
            background-image: url("/assets/images/general/material-symbols_keyboard2.svg");
          }
        }
      }
    }

    .more-links {
      font-size: 0.875rem;
    }

    #contact-block .contact-box {
      background: #000916;

      .d-flex {
        background: #000916;
      }
    }

    #contact-block form .form-control {
      color: #fff;
      background: #203351;
    }

    .titleForCallback {
      color: #fff !important;
    }

    .forCallBackLable {
      color: #3769ff !important;
    }

    .fund-head {
      .title-text:hover {
        // span {
        color: Blue !important;
        // border-bottom: 1px solid var(--blue);
        // }
      }

      .title-text {
        // span {
        color: #fff !important;
        // border-bottom: 1px solid var(--blue);
        // }
      }

    }

    #exampleFormControlTextarea1::placeholder {
      color: #fff;
      /* Replace with the color you want */
    }

    .page-dark-mode .modal .modal-body form .form-control,
    .page-dark-mode .modal .modal-body form .form-select {
      background-color: #203351;
      color: #fff;
    }

    #callBack.modal.show .modal-content .modal-body form textarea.form-control {
      color: #fff;
      background: #203351;
    }

    .forColorStandType {
      background: #203351 !important;
      color: #fff !important;
    }

    .filterFundsColorView {
      background: #000916 !important;
    }

    .forFilterColorBy {
      color: #fff !important;
    }


  }


  .FT-general-filter.cart-aside .filter-box {
    .filter-content {
      background: #0C1738;
      color: #FFF;

      .nav-tabs {
        background: #192753;

        .nav-link.active {
          color: #FFF !important;
        }
      }

      .cart-box {
        background: #192753 !important;

        .text-big {
          color: #FFF !important;
        }

        .price-input {
          background: #09172D;

          .form-control {
            background: #09172D;
            color: #FFF;
          }
        }

        .custom-check.form-check .form-check-label {
          background: #09172D !important;
          color: #FFF;
          border: 1px solid #D2D8EA;
        }

        .form-group {
          background: #09172D;

          .form-control {
            background: #09172D;
            color: #FFF;
          }
        }
      }
    }

    .forTitle {
      color: #FFF;
    }

    .filter-footer {
      background: #0C1738;
    }
  }

  .FT-general-filter.show .filter-box {
    background: #0C1738;
  }


  .text-small {
    color: inherit !important;
  }

  .blog-mobile-card {
    // box-shadow: 2px 2px 9px 3px rgb(244 244 245 / 43%)!important;
    box-shadow: 0px 4px 38px rgba(16, 27, 66, 0.2) !important;
  }

  .digitize-card-heading {
    color: inherit;
  }

  .accordion-button-style {
    background: #011026 !important;
  }

  .FT-small-menu {
    &.opened {
      background: var(--dark-body-bg);
    }

    .accordion-item {
      .accordion-body {
        background: #0C1738;

        .dropdown .dropdown-toggle {
          color: white;
        }

        .dropdown .dropdown-menu {
          .flex-block {
            background: #000916;
          }

          .show {
            background: inherit;
          }
        }

        .dropdown ul {
          background: #091830;
        }

        .dropdown ul li .dropdown-item {
          background: #223B83;
          color: #fff;
        }
      }

      .accordion-button {
        background: #223B83;
        color: white;
      }
    }

    .theme-options .form-switch .form-check-input:checked {
      background-color: inherit;
      border: 2px solid white;
    }

    .theme-options {
      .theme-font {
        // &-.small,&-.medium,&-.big {
        color: white;
        background: #223B83;
        // }
      }
    }

  }

  .dark-mode-icon {
    display: block !important;
  }

  .light-mode-icon {
    display: none !important;
  }

  .img-with-tooltip {
    top: 1px !important;
  }

  .search-result1 {
    background: #000916 !important;
    // border: none!important;
    color: #fff;
  }

  .FT-all-funds {
    background: #000916 !important;

    .advanced-filter .filter-content {
      h3 {
        color: #fff;
      }

      background: #0C1738;
    }

    .filter-footer .forFooterFilterToggle {
      color: #FFFFFF !important;
      background: #223B83 !important;
    }

    .filters .filter-footer {
      background: #223B83 !important;
      border-radius: 0px 0px 12px 12px;
    }

    .filter-selected-options .option {
      border: none;
      background: #557FFF;
    }

    .ngx-slider .ngx-slider-tick-legend {
      color: #FFF;
    }

    .forminmaxsize,
    .forageminmax {
      color: #FFF;
    }

    .filters .more-option-btn {
      color: #FFFFFF !important;
    }

    .filters {
      border: 1px solid #000916;
    }

    .sort-bottom {
      background: #0C1738;
      color: inherit;
    }

    background: #000916;

    .position-stiky {
      background: #000916;
    }

    .filter-title {
      background: inherit !important;
    }

    .search-result {
      // background: #000916;
    }

    .filter-options {

      input,
      .btn {
        &::placeholder {
          color: #fff;
        }

        background-color: #09172D;
        color: #fff;
      }

      .dataFilter {
        color: #fff;
      }
    }

    .filters {
      .accordion-item .accordion-header .accordion-button {
        background: #0C1738 !important;
        color: #fff;

        &::after {
          background-image: url("../../assets/images/general/Vector.png");
        }
      }

      .filter-head {
        background: #223B83;
        color: inherit;
      }

      .accordion-item {
        background: #0C1738;
        color: #fff;
      }
    }

    .inner-filters {
      background: #0C1738;
      color: #fff;
      margin: 0px;
    }

  }

  .watchlist-funds .watchlist-tab-box {
    background: #0C1738;

    .nav-link {
      color: #fff;
    }
  }

  .watchlist-funds .watchlist-tab-box .nav-link.active {
    color: var(--blue);
    border-bottom-color: var(--blue) !important;
  }

  .forwatchlistchangetab {
    .nav-tabs .nav-link.active {
      color: #fff !important;
      border-bottom-color: var(--blue) !important;
    }
  }

  .watchlist-funds.empty-watchlist .watchlist-box {
    // background: #0C1738;
    background: #000916;
  }

  .darkOffcanvasAddbody {
    background: #000916;
  }

  .FT-all-funds .fund-block {
    background: #0C1738;
    color: #fff;
    border: 1px solid #fff;
  }

  .FT-all-funds .search {
    background: #09172D url("/assets/images/fund/MagnifyingGlass.svg") no-repeat left 12px center !important;
  }



  .watchlist-funds.empty-watchlist .nav-tabs .nav-link.active {
    color: var(--blue);
    border-bottom-color: var(--blue) !important;
  }

  .FT-watchlist-content-sticky-tab {
    background: #000916 !important;
    color: #fff !important;
  }

  .Ft-watchlist-content-filter-head {
    background: #223B83 !important;
    color: #fff !important;
  }

  .FT-Watchlist-Content-Color-Checkout {
    background: #0C1738 !important;
    color: #fff !important;
  }

  .checkGoToCheckout {
    .checkAdded {
      color: #fff !important;
    }
  }

  .FT-knowledge-tabs {
    background: #13294b !important;

    .nav-pills {
      background: #0C1738;

      .nav-link {
        background: #0C1738;
      }

      .nav-link.active {
        background-color: #13294b;
        color: #fff;
      }
    }

    .tab-content {
      background: #0C1738;

      h3 {
        color: #fff;
      }

      .compareCardBox {
        background: #000916;
        color: #fff;

        .midCard {
          background: #0C1738;
        }

        .valueMidCard {
          color: #fff;
        }

        .valuMidCardData {
          color: #fff;
        }

        .totalExsitingInvestors {
          color: #fff;
        }
      }
    }
  }

  .makeCardWithCompare {
    background: #000916;
  }

  .cartSipInvestment {
    background: #000916 !important;
  }

  .investmenttData {
    background: #000916 !important;
    color: #fff !important;

    .titlecolor {
      color: #fff !important;
    }
  }

  .makeCardFitData {
    background: #0C1738;
    color: #fff !important;
  }

  .addCheck {
    color: #fff !important;
  }

  .checkGoToCheckout .addColorCard {
    background: #0C1738 !important;
    border: 1px solid #D2D8EA;
    border-radius: 8px;
  }

  .makeAddCartData {
    background: #000916 !important;
  }

  .forOtherCardColor {
    .addTitleCart {
      //    color: #fff!important;
    }

    .addValueData {
      color: #fff !important;
    }
  }

  .offcanvas-body {
    background-color: #000916;
  }

  .forInvestmentCartColor {
    color: #fff !important;
  }

  .addForDivColor {
    background: #000916 !important;

    .cartInteger {
      color: #fff !important;
    }

    .cartText {
      color: #fff !important;
    }

    .cartInvestor {
      color: #fff !important;
    }
  }

  .colorExpandsColorView {
    background: #000916 !important;
  }

  .choose-plan-wraper .choose-plan-title {
    background: #000916 !important;
    color: #fff !important;
  }

  .FT-all-funds .filter-options .dropdown .dropdown-menu {
    background: #000916 !important;
  }

  .FT-comparefund-wrapper table>tr:first-child>th:first-child {
    background-color: #13294B !important;
  }

  .FT-comparefund-wrapper table>tr:first-child {
    background: #13294B !important;
  }

  .FT-comparefund-wrapper table>tr:nth-last-child(odd):not(:first-child):not(:nth-child(2)) td {
    background-color: #000916 !important;
  }

  .FT-comparefund-wrapper table>tr:nth-last-child(even):not(:first-child):not(:nth-child(2)) td {
    background-color: #0C1738 !important;
  }

  .FT-comparefund-wrapper table>tr:first-child>th .fund-card-wrap {
    background-color: #000916 !important;
  }

  .FT-comparefund-heading {
    background: #000916 !important;
  }

  .makeCompareMutualColor {
    background: #000916 !important;
  }

  .form-group input::placeholder {
    color: #fff !important;
  }

  // .form-group input {
  //     background-color: #203351 !important;
  // }


  .form-group select {
    // background-color: #203351 !important;
    color: #fff !important;
  }

  .form-group select::placeholder {
    color: #fff !important;
  }

  .form-group select option {
    background-color: #13294b !important;
    color: #fff !important;
  }

  .form-group select option:disabled {
    color: #fff !important;
  }

  .FT-knowledge {
    background: #000916;

    .FT-blocks-nav {
      border: 0;
      background: #0C1738;

      ul li a.active-item {
        background: linear-gradient(90deg, #583EE7 26.99%, #0EA0E6 50.09%, #00B6AE 74.69%);
        color: #fff !important
      }
    }

    .knowledge-box {
      background: #192753;

      .FT-ad-box {
        border: 2px solid #D2D8EA;

        .btn-primary {
          background: #264DBE;
        }
      }


      .filter-block {
        .search {
          background: #192753 url("/assets/images/dark/search-icon-white.svg")no-repeat left 12px center;
          color: #FFF !important;
        }

        .dropdown {
          .dropdown-toggle::after {
            width: 18px;
            height: 18px;
            background-image: url("/assets/images/dark/arrow-down-white.svg");
            background-size: 18px 18px;
          }
        }

        .btn {
          border: 1px solid #C4C4C4;
          background: #192753;
          color: #fff
        }

        .show-light-mode {
          display: none;
        }

        .show-dark-mode {
          display: inline-block;
        }
      }

      .category-btns {
        ul {
          li {
            color: #fff;
            border: 1px solid #7F869C;
            background: transparent !important;

            &.active {
              border-color: #fff;
              background: #223B83 !important;
            }
          }
        }
      }

      &.categories {
        .btn-white {
          color: #fff;
          border: 1px solid #192753;
          background: #557FFF;

          /* Shadow/xs */
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        }
      }
    }
  }

  .FT-knowledge .knowledge-box.academy .inner-box {
    .FT-title-medium {
      color: var(--black);
    }
  }

  .FT-calculator-accordion .accordion .accordion-item .accordion-collapse .accordion-body a {
    color: #fff !important;
  }

  .FT-article-content {
    .article-title {
      h3 {
        color: #fff !important;
      }

      .text-small .tag {
        color: #fff;

        &::before {

          background: #fff;
        }
      }

      .icons {
        color: #fff;

        .card-icons {
          color: #fff;
          position: relative;
          bottom: 6px;
        }
      }
    }

    .article-img-block .audio-block {
      background: #192753;

      .progress {
        .bg-success {
          background: #557FFF !important;
        }
      }
    }

    .content {
      color: #fff;

      h3 {
        color: #fff;
      }

      .btn-light-primary {
        border-color: #304fb2;
        color: #fff;
        background: rgba(71, 102, 197, 28%);
      }

    }

    .toggle-content-box {
      border-radius: 8px;
      border: 1px solid #D2D8EA;
      background: #192753;

      span {
        color: #8189A2;

        &.active {
          background: #223B83;
          color: #fff;
        }
      }
    }

    .article-language .languages-box .languages {
      .custom-check.form-check .form-check-label {
        color: #fff;
        border: 1px solid #fff;
        background: transparent;
      }

      .custom-check.form-check .form-check-input:checked[type=checkbox]+.form-check-label,
      .custom-check.form-check .form-check-input:checked[type=radio]+.form-check-label {
        background: #192753;
      }
    }
  }

  .FT-related-tags {
    background: #192753;

    .tags-list {
      color: #fff;

      .tag {
        color: #ffff;

        &::before {
          background: #fff;
        }
      }
    }
  }

  .category-card .card-body .short-descriptiton {
    color: #fff;
  }

  .FT-podcast {
    .category-card {
      background: #192753;
    }

    .podcast-details {
      .category-card.big-card {
        .subtitle {
          color: #fff !important;
        }

        h2 {
          color: #fff;

          small {
            color: #fff;
          }
        }

        .text-gray {
          color: #fff;
        }
      }
    }

    .podcast-list {
      .category-card {
        .card-body {
          h3 {
            color: #fff;
          }

          .tag {
            color: #fff;
            background: #223B83;
          }

          .audio-play-option {
            color: #fff;
            background: #223B83;
          }
        }
      }
    }
  }

  .fund-head {
    .title-text:hover {
      // span {
      color: blue !important;
      // border-bottom: 1px solid var(--blue);
      // }
    }

    .title-text {
      // span {
      color: #fff !important;
      // border-bottom: 1px solid var(--blue);
      // }
    }
  }

  #contact-block .contact-box {
    background: #000916;

    .d-flex {
      background: #000916;
    }
  }

  #contact-block form .form-control {
    color: #fff;
    background: #203351;
  }

  .titleForCallback {
    color: #fff !important;
  }

  .forCallBackLable {
    color: #3769ff !important;
  }

  #exampleFormControlTextarea1::placeholder {
    color: #fff;
  }

  .forColorStandType {
    background: #13294b !important;
    color: #fff !important;
  }

  .FT-fund-statistics .fund-block .Calculate-box .calc-stat {
    color: #FFFFFF
  }

  .range-group input {
    color: #FFFFFF;
  }

  .FT-fund-more-details .riskometer ul {
    color: #FFFFFF;
  }

  .img-with-tooltip {
    .tooltip-block {
      // background-color: #223B83;
      // color: #FFFFFF;
      background-color: #FFFFFF;
      color: #000916;
    }
  }

  .d-flex .forCheckoutView {
    background-color: #112138;
  }

  .forCheckoutView {
    background-color: #0C1738 !important;
  }




  // .fund-block .icons button img {
  //     .svg-stroke {
  //         stroke:#FFFFFF
  //     }
  // }
  .FT-general-filter.cart-aside .filter-box .filter-content .btn-transparent {
    color: #FFFFFF;
  }

  .modal .modal-body .btn {
    color: #FFFFFF;
  }

  .fade .btn-close {
    background: url("/assets/images/dark/dark-close-icon.png") no-repeat left 0px center;
  }

  .offcanvas {
    color: #FFFFFF;
  }


  // media page-- mobile view dark mode changes

  @media(max-width: 575.98px) {
    .FT-footer-pages .media-contact {
      background-color: #000916;
    }
  }


  @media(max-width: 767.98px) {
    .FT-knowledge .knowledge-box {
      background-color: #000916;
    }
  }

  @media(max-width: 575.98px) {
    .FT-footer-pages .release-container {
      background-color: #000916;
    }
  }


  @media(max-width: 767.98px) {
    .FT-knowledge #releases {
      // background-color: #223B83;
    }
  }


  // terms and condition-- mobile view dark mode changes

  @media(max-width: 575.98px) {
    .terms-bg-color {
      background-color: #000916;
    }
  }

  @media (max-width: 767.98px) {
    .FT-footer-pages .accordion-item {
      background-color: #223B83;
    }

  }

  @media(max-width: 767.98px) {
    .FT-footer-pages .accordion-item .accordion-header .accordion-button {
      color: #FFFFFF;
    }

  }

  @media (max-width: 767.98px) {
    .FT-footer-pages .accordion-item .accordion-body {
      color: #FFFFFF
    }
  }

  // knowledge-center dark mode

  .FT-general-filter .filter-box .filter-content {
    color: #FFFFFF
  }

  .FT-general-filter .filter-box h3 {
    color: #FFFFFF
  }

  .FT-general-filter .filter-box .filter-footer {
    background-color: #192753;
  }

  .FT-podcast .FT-calculator-accordion .accordion .accordion-item .accordion-collapse .accordion-body ul li strong {
    color: #FFFFFF;
  }

  .for-answer-question-input {
    color: #FFFFFF !important;
  }

  .for-answer-question-input-title {
    color: #FFFFFF !important;
  }

  .modalTitle {
    color: #FFFFFF !important;
  }

  .fade .btn-close {
    background: url("/assets/images/dark/dark-close-icon.png") no-repeat left 0px center;
  }

  .for-to-next-question-tab {
    color: black !important;
  }

  .search-result .inner {
    background: #09172D !important;
  }

  .search-result .sub .text-gray {
    color: #ffff !important;
  }

  .fund-search-filter-title {
    color: #ffff !important;
  }


  // KC share icon
  .dropdown .dropdown-menu {
    background: #112138 !important;

  }

  .dropdown-item {
    color: #FFFFFF !important;
  }

  // Kc artical mobile view

  @media (max-width: 767.98px) {
    .FT-article-content {
      background-color: #000916;
    }

    .FT-calculator-accordion {
      background-color: #000916;

    }
  }

  // KC blog dark mode
  .category-dropdown .dropdown-menu .nav-tabs {
    background-color: #091830;
  }

  .custom-check.form-check .form-check-label {
    background-color: #212529;
  }

  .custom-check.form-check .form-check-label {
    border-radius: 32px;
    border: 1px solid #7F869C;
    color: #fff;
  }

  .FT-knowledge .knowledge-box.categories h3 {
    color: #fff;
  }


  @media (max-width: 767.98px) {
    .dropdown-menu {
      border-bottom: 0;
      background: #011026;
    }
  }

  @media (max-width: 767.98px) {
    .FT-article-content .article-title .icons .card-icons {
      position: relative;
      bottom: 8px;
    }
  }

  // category page dark ime-mode

  .FT-category-ad .d-flex .text-big {
    color: #3c425d;
  }

  .FT-category-ad .d-flex .text-meduim {
    color: #3c425d !important;
  }

  @media (max-width: 798.98px) {
    .subtitle-first-heading {
      color: #FFF !important;
    }

    .podcast-tital {
      color: #FFF !important;
    }
  }


  // KC podcast
  @media(max-width: 798.98px) {
    .category-card .card-icons {
      color: #FFF !important;
    }
  }


  @media(max-width: 798.98px) {
    .category-card .card-body .card-icons {
      position: relative;
      bottom: 7px !important;
    }
  }

  // podcast mobile
  @media(max-width: 798.98px) {
    .audio {
      color: #fff !important;
      background: #223B83 !important;
    }

    .progress {
      background-color: #0d6efd !important;
    }


  }


  // investor service in account statemnent

  .bg-white {
    background: #13294b !important;
  }

  .FT-investor-services .font-medium {
    color: #fff;
  }

  .subscribe-bg-gradient {
    background-image: var(--gradient) !important;
  }

  .form-control {
    background: #13294b;
    color: #fff;

  }& ::placeholder {
     color: #fff;
   }

  .FT-investor-services .mailback-box .more-options {
    color: #fff;
  }

  @media(max-width: 767.98px) {
    .FT-investor-services .bg-white-shadow {
      background-color: #000916;
    }

    // background: #13294b !important;
  }

  @media(max-width: 767.98px) {
    .FT-knowledge-tabs {
      background-color: #0C1738 !important;
    }

  }

  @media(max-width: 767.98px) {
    .page-dark-mode .FT-knowledge-tabs .nav-pills .nav-link.active {
      background: #13294b;
      color: #fff;
    }

  }

  // lading page investor service
  .bg-berry {
    background: #011026 !important;
  ;
  }

  .FT-investor-links .link-box {
    background: rgba(255, 255, 255, 0.08);
    color: #FFFFFF;
  }

  .FT-investor-links .text-dark-gray {
    color: #fff
  }

  // update personal details
  .goal-cards {
    background-color: #13294b;
  }

  .steps .step-item {
    background-color: #13294b !important;
  }

  // forms-dawnload page

  .FT-footer-pages.forms-download .filter-box .filter-date {
    // background:#192753;
    background: transparent !important;
  }

  .FT-footer-pages.forms-download .filter-box .filter-date form .date-group {
    background: #000916;
  }

  .fillter-date {
    background: #000916 !important;
    color: #fff;

    &::placeholder {
      color: #fff;
    }

  }

  .bg-color {
    // background: #000916;
  }

  // .FT-footer-pages.forms-download .category-btns ul li {
  //     background: #000916;
  //     color: #fff;
  // }
  .FT-footer-pages.forms-download .category-btns ul li {
    background: #000916;
    color: #fff;
  }

  .FT-footer-pages.forms-download .category-btns ul li.active {
    background: #3769ff;
    color: #fff;
  }

  .FT-footer-pages.forms-download .form-tabs .form-group .form-control {
    border-radius: 8px;
    border: 1px solid #C4C4C4;
    background: #000916;
    padding: 8px 12px;
    background: #000916 url("/assets/images/dark/search-icon-white.svg") no-repeat left 12px center;
    background-size: 15px;
    padding-left: 36px;
    font-size: 0.875rem;
    outline: none;
    box-shadow: none;

    &:focus {
      border-color: #fff;
    }
  }

  .FT-footer-pages.forms-download .inner-accordion .accordion-item .accordion-header .accordion-button {
    // background: transparent !important;
    background: #192753;
    color: #FFFFFF;
  }

  .FT-footer-pages .reports-box table tbody tr td {
    background: #0c1738;
    color: #fff;
  }

  .FT-footer-pages.forms-download .inner-accordion .accordion-item {
    background: #000916;
  }

  .FT-footer-pages.forms-download .filter-box .text-big {
    color: #fff;
  }

  @media(max-width: 767.98px) {
    .FT-footer-pages.forms-download .form-tabs .nav-tabs .nav-link {
      background-color: #000916;
      color: #FFFFFF !important;
      border: 1px solid #fff;
    }
  }

  .table-striped {
    &>tbody>tr:nth-of-type(odd)>* {
      // --bs-table-bg-type: #fff;
      --bs-table-bg-type: #000916;
      color: #fff;
    }
  }

  .accordion-button::after {
    background: url("/assets/images/dark/down arrow.png") no-repeat left 0px center;
    font-size: 6.25rem;
    // background: url(/assets/images/dark/dark-close-icon.png) no-repeat left 0px center;
    background-repeat: no-repeat;

  }

  @media(max-width: 991.98px) {
    .flex-row {
      color: #fff;
    }

    .for-add-offcanvas-bottom {
      background: #000916 !important;
      border: 1px solid #fff;
    }
  }

  // fund datails
  .btn-date-selector {
    color: #fff;
  }

  .FT-performance .performance-head ul li .bg-white .form-group .form-control {
    color: #fff;
  }

  .FT-performance .performance-head ul li .bg-white {
    color: #fff;
  }

  .fund-details-build-india {
    background: #13294b !important;
    color: #FFFFFF;
  }

  .popover-body {
    background: #0c1738;
    color: #fff;
  }

  .FT-fund-statistics .fund-block .returns-block .nav-tabs .nav-item .nav-link {
    color: #feffff;
  }

  // explore all fund
  .table-striped {
    --bs-table-bg: #192753;
    color: #fff;
  }

  .modal .table-box .table-part .table-responsive table td {
    color: #FFFFFF;
  }

  .modal .table-box .table-part .table-responsive table th {
    --bs-table-bg: #2f5ad5;
    color: #fff;
  }

  .modal .table-box .table-part .table-responsive table th,
  .modal .table-box .table-part .table-responsive table td {
    --bs-table-bg: #2f5ad5;
  }

  // .table thead th {
  //     --bs-table-bg: #2f5ad5;

  // }
  .modal .table-box .table-part .table-pagination {
    background-color: #2f5ad5;
  }

  .table-page-bg {
    // --bs-table-bg: #2f5ad5;
    background: #2f5ad5;
  }

  .report-tital {
    color: #fff;
  }

  .report-bg {
    background: #000916 !important;
  }

  .report-date-group {
    background: #000916 !important;
  }

  // forms-downlod
  #bg-form {
    background-color: #000916 !important;

  }

  // location
  #myTabContent {
    background-color: #000916 !important;

  }

  #location-dark-color {
    color: #fff;
  }

  #location-tital {
    color: #fff;
  }

  #btn-dark ul li {
    background-color: #000916;
    color: #fff;

    &.active {
      background: #3769ff;
    }
  }

  .FT-navs-funds .tabs-block {
    background-color: #000916;
  }

  .contact-box {
    background-color: #000916 !important;

  }

  .nav-alert-dark {
    background-color: #000916 !important;
  }

  .nav-form {
    background-color: #000916 !important;

  }

  .FT-footer-pages.faq .inner-accordion .accordion-item .accordion-body {
    background: #192753 !important;
    color: #fff;
  }

  .location-btn {
    border: 1px solid #C4C4C4 !important;
  }

  .FT-touchPoints .locations-box {
    background-color: #0C1738;
    color: #fff;
  }

  .FT-touchPoints .locations-box .row:nth-child(even) {
    background-color: #13294b;
  }

  .FT-touchPoints .locations-box .text-gray {
    color: #FFFFFF;
  }

  .FT-touchPoints .locations-box .text-big {
    color: #FFFFFF;
  }

  .for-selected-fund {
    color: #fff;
  }

  @media (max-width: 767.98px) {
    .for-watchlist-box {
      border-right: none !important;
      background: #09172d;
    }
  }

  @media(max-width: 767.98px) {
    .FT-footer-pages .media-contact .contact-box {
      background: #112138 !important;
      border: 1px solid #fff;
    }

    .FT-footer-pages .media-contact .contact-box h3 {
      color: #FFFFFF !important;
    }
  }

  @media(max-width: 991.98px) {
    #offcanvasBottomEmail,
    #offcanvasBottomSpeak,
    #offcanvasBottomLocate,
    #offcanvasBottom,
    #offcanvasBottom2,
    #offcanvasBottomFilter,
    #getcardoffcanvasBottom,
    #offcanvasBottom3 {
      background: #000916;
      border: 1px solid #fff;
    }

    .offcanvas .offcanvas-header .modal-title.custom-modal-title,
    .offcanvas .offcanvas-header .modal-subtitile, .offcanvas-header {
      color: #FFFFFF !important;
    }
  }
  // distributor landing page web view dark mode
  .FT-quick-links .quick-links-box {
    background: #141c28;
    color: #fff;
    border: 1px solid #fff;
  }

  .FT-distributor-features{
    background: #011026;
  }

  .FT-distributor-features .feature-box {
    background: #152337;
    border: 1px solid #fff;
  }
  .FT-digital-offers .offer-box{
    background: #141c28;
  }

  .FT-distributor-events{
    background: #011026;
  }

  .testimonial-box{
    background: #141c28;
    color: #FFFFFF;
  }
  .FT-faq {
    background: #011026;
  }
  .FT-short-links{
    background: #011026;
  }
  // invest now dark mode
  .FT-invest .outer-box {
    background-color:#152337;
  }

  .FT-login-form .form-box {
    background: #13294b;
  }
  .FT-invest .invest-head-box{
    color: #fff;
  }
  // login dark mode
  .FT-login-form .outer-container{
    background: #011026;
  }

  .FT-calculator-box
  .FT-financial
  .tab-content.calculator-box
  .calculate-statics
  .table-responsive table thead th {
    color: #fff;
  }
  .FT-calculator-box .FT-financial .tab-content.calculator-box .calculate-statics .table-responsive table td{
    color: #fff;
  }


  // our pepole
  .about-box{
    background: #152337;
    // border: 1px solid #F0F6FF;
  }
  .FT-Franklin-info .info-box .total-funds{
    background: #152337;
    // border: 1px solid #F0F6FF;
  }

  .FT-Franklin-info .info-box .products-info{
    background: #152337;
    // border: 1px solid #F0F6FF;
  }

  .FT-Franklin-info .info-box .content-box{
    background: rgb(0 9 22);
  }

  .vintage-box{
    background: #152337;
    // border: 1px solid #F0F6FF;
    box-shadow: none;
  }
  .people-content{
    border-left: 3px solid #7F869C !important;
    border-right: 3px solid #7F869C !important;
  }

  // about first page
  .bg-light-blue {
    background: #011026;

  }

  .FT-about-page .feature-box {
    background: #152337 !important;
    border: 1px solid #F0F6FF;
    box-shadow: none;
  }

  .FT-about-page p {
    color:#fff;
  }

  .FT-about-page p {
    color: #fff !important;
  }

  .FT-about-page .statistics{
    color: #fff !important;
  }

  @media (max-width: 991.98px){
    .FT-about-page .about-img-box,.FT-about-details .container {
      background: #152337;
    }
    .FT-Franklin-info {
      background: #030c18;
    }
  }
  // who we are
  .about-box h3{
    color: #FFFFFF;
  }

  .Ft-num {
    background-image: linear-gradient(89.89deg, #fff 26.92%, #fff 57.79%, #fff 90.66%) !important;

  }

  // our people first page
  .FT-people .member-box {
    background: #152337;
  }
  .FT-people .member-box h2, .FT-people .member-box h3, .FT-people .member-box {
    color: #FFFFFF;
  }
  // calculater table
  #myTabContent2{
    background: #223b83 !important;
  }


  .FT-concepts .faq-box .accordion .accordion-item {
    background: #152337 !important;
    border-bottom: 1px solid rgba(222, 228, 247, 0.5019607843);
    // box-shadow: none;
  }

  // csr page
  .csr-banner{
    background: #091830 !important;
  }

  .FT-csr-info .csr-box {
    background: #091830;


  }
  .FT-csr-info .csr-box .side-info li {
    background: #192753;
    box-shadow: none;
  }

  .FT-csr-info .csr-box:nth-child(even), .FT-csr-info .csr-box, .FT-csr-info .csr-box h2, .FT-csr-info .csr-box .side-info li .content h3 {
    color: #fff !important;

  }

  // what diff us
  .FT-proposition .content .tab-content {
    background: #152337 !important;
  }

  .Ft-pro-num{
    color: #fff !important;
  }

  // who we are mob
  @media (max-width: 767.98px){
    .about-box {
      background: #13294b;
    }
    .faq-box-mob {
      background-color: #000916 !important;
    }
  }

  // login page - invester

  .FT-empanel .empanel-box {
    background: #13294b;
  }

  .FT-login-form .form-head h2 span,
  .invest-register, .distiribute-register{
    color: #fff;
  }

  // lead gen form

  .lead-gen-block {
    background: #09172d !important;
    border: 1px solid #D2D8EA !important;
  }

  // feedback-form
  .feedback-form {
    background: #0C1738 !important;
    border: 1px solid #7F869C !important;
  }

  .rating-box {
    background: #192753 !important;
  }

  .category-btns ul li.active {
    background: var(--blue) !important;
    // color: #fff;
  }
  .category-btns ul li {
    color: #fff !important;
    border: 1px solid #7F869C !important;
    background: #192753 !important;
  }


  //    KC-Investment glossary dark mode
  .FT-glossary .search-group .form-control,
  .FT-glossary .filter-box {
    background-color: #13294b;
  }

  .FT-glossary .filter-box .filter-body .form-check .form-check-label {
    background: #0c1738;
  }

  .FT-glossary .filter-box .filter-head {
    background: #223B83;
  }

  .FT-glossary .glossary-box .letter-title, .table td, .table th {
    color: #fff;
  }

  .FT-glossary .toggle-filter {
    background: #13294b;
    color: #fff;
  }

  // Become a Distributor

  .FT-empanel .pdf-files {
    background: #223b83;
  }

  .FT-empanel .pdf-files .file-box{
    background: #192753;
  }

  .FT-empanel .distributor-box {
    background: #223b83;
  }

  .FT-empanel .sarch-code-box {
    background-color: #13294b;
  }

  // login page invester mob-view
  @media (max-width: 767.98px){
    .FT-login-form .outer-container{
      background: #13294b !important;
    }
  }



  #historic-nav-canvas {
    background-color: #091830 !important;
    color: #fff;
    // border-bottom: 1px solid #fff !important;
  }

  .historic-nav-drop{
    background-color: #091830 !important;
    color: #fff !important;
  }

  .selectFundDetails{
    background-color: #0c1738 !important;

  }

  #report-filter {
    background: #0C1738 !important;
    color: #FFFFFF !important;
    border: 1px solid #7F869C;
  ;
  }
  .filter-footer {
    background-color: #0c1738 !important;
  }


  @media (max-width: 820px){
    .FT-footer-pages.forms-download .form-tabs .nav-tabs .nav-link{
      border: 1px solid #fff !important;
      color: #fff !important;
      background: #0c1738 !important;
    }

  }

  #dark-mode-on {
    background-color: #233b83 !important;
  }

  #search-content{
    background-color: #000916 !important;
  }
  #search-bar{
    background-color: #192753 !important;
    color: #fff !important;
  }

  .search-results{
    background-color: #000916 !important;
  }

  .search-results ul li a {
    color: #fff !important;
  }
  .search-results .d-flex {
    color: #fff !important;

  }

  .fund-page-filter{
    background-color: #223B83 !important;
  }

  .goal-cards .card-body .alert-danger{
    color:#58151c !important;
  }

  .choose-plan-title {
    background-color: #000916 !important;
    color: #fff;

  }
  .stepper-card-wrap {
    background: #223b83 !important;
  }

  .solution-debt{
    background-color: #192753 !important;
  }



  .goal-pl .funds-content .alert-danger {
    color:#58151c;
  }

  .added-funds-title, .goal-pl .choose-plan-text {
    color: #fff !important;
  }


  .added-funds-subheader {
    background-color: rgb(19 41 75) !important;
  }

  .added-funds-percentage{
    background: #09172D !important;
    color: #fff !important;

  }

  .input-add-fund{
    background: #09172d !important;
    color: #fff !important;
  }

  .goal-summary-bg {
    background: #112138 !important;
    color: #fff !important;
  }

  .goal-summary .card-title {
    color: #fff !important;
  }

  .goal-sum-invest-popup {
    background: #192753 !important;
  }

  .offcanvas.offcanvas-en .offcanvas-body {
    color: #fff !important;
  }

  #home-tab-pane2 {
    background-color: #0c1738 !important;
  }

  .cart-date {
    background-color: #13294b !important;
  }

  .for-selected-fund {
    color: #fff !important;
  }

  .nav-alert-fund {
    color:#fff !important
  }
  .for-question-get-title {
    color: #fff !important;
  }

  // search mob dark mode
  .sm-search-block{
    background-color: #000916 !important;
  }
  .sm-search-block .recent-search .text-bold, .sm-search-block .recent-search a{
    color: #fff !important;
  }

  .sm-search-block .block-head .form-group {
    background-color: #13294b !important;
    border: 1px solid #fff !important;
  }

  .bi, .bi-arrow-left {
    color: #fff !important;
  }
  .search-result{
    color: #fff !important;
  }

  .FT-footer-pages .reports-box table thead tr th{
    background: #0c1738 !important;
  }

  .FT-category-ad {
    background: #09172d !important;
  }

  .FT-category-ad .d-flex .text-big, .FT-category-ad .d-flex .text-meduim {
    color:#fff !important;
  }

  .FT-location .box-shadow h3 {
    color: #fff !important;
  }
  .filter-container .inner-filters .nav-link:not(.active){
    color: #fff !important;
  }

  .FT-portfolio .portfolio-graphs a{
    color: #fff !important;
  }

  .link-test{
    color: #fff !important;
  }

  .link-text{
    color: #fff !important;

  }

  .link-text, .link-test{
    &:hover{
      border-bottom: 2px solid #fff !important;
    }
  }

  .block-border .block-footer .download-plan a {
    color: #fff !important;
  }

  // nav-page

  .FT-all-funds .advanced-filter .filter-content .accordion-item .accordion-body{
    color: #fff !important;
  }

  .you-tube{
    color: #fff !important;
    border: 1px solid #fff !important;
  }

  .nav-first-name, .discl-nav {
    color: #fff !important;
  }

  .nav-contact-box {
    background: #09172d !important;
  }

  .text-small:after{
    background-color: #fff !important;
  }

  .line:after {
    background-color: #fff !important;
  }

  .nav-date{
    background: #09172d !important;
  }

  .font-resize-wraper > li > button {
    color:#fff;
  }

  header .top-menu-block .links li:hover .dropdown-menu{
    border-bottom: 1px solid #fff !important;
  }

  .otherlinks:hover {
    border-bottom: 1px solid #fff !important;

  }

  .FT-financial .graph-table-btns .btn {
    color: #dfe2eb;
  }

  .FT-all-funds .filter-options .dropdown .dropdown-menu li .dropdown-item {
    color: #fff !important;
  }

  .FT-all-funds .filter-options .dropdown .dropdown-menu li:hover, .FT-all-funds .filter-options .dropdown .dropdown-menu li.selected-option {
    background-color: #223b83 !important;
    color: #FFFFFF !important;
  }

  .FT-knowledge .knowledge-box .filter-block .dropdown .dropdown-menu li:hover {
    background-color: #223b83 !important;
    color: #FFFFFF !important;
  }

  .login-dark, .login-dark2 {
    color: #fff !important;
  }

  .step-title {
    color: #fff !important;
  }

  .solution-img {
    background-color: #0c1738 !important;
  }

  .time-tag {
    background-color: #223b83;
  }


  // Header font resize
  .font-resize-wraper{
    > button{
      color: #fff;
    }
    > button.activeResize{
      background-color: #223B83 !important;
    }
  }

  // Dark mode doggle button
  .dark-mode-wrap{
    background-color: #223B83 !important;
    .light-active{
      display: none;
    }
    .light-inactive{
      display: block !important;
    }
    .dark-active{
      display: block !important;
    }
    .dark-inactive{
      display: none;
    }
  }


  .graph-block .graph-head {
    color: #fff !important;
  }

  .relet-resour {
    background-color: #0c1738 !important;
  }

  .dropdown-item:active {
    background-color: #0c1738;
  }
  .dropdown-item:hover {
    background-color: #0c1738;
  }

  .FT-hero .carousel .carousel-control-prev i, .FT-hero .carousel .carousel-control-next i {
    background-color: #000916 !important;
    &:hover {
      // background: #000916;
      background-color: rgb(85, 127, 255) !important;
    }

  }

  .FT-calculator-box .FT-financial .tab-content.calculator-box .selected-range,
  .FT-calculator-box .FT-financial .tab-content.calculator-box .calc-input {
    border-color: #FFFFFF;
    color: #fff;
    &:focus-within {
      color: #000916;
    }
  }

  // .FT-calculator-box .FT-financial .tab-content.calculator-box .selected-range {
  //     border-color: #FFFFFF;
  //     color: #fff;
  //     &:focus{

  //      color: black !important;

  //             }

  // }


  // .FT-calculator-box .FT-financial .tab-content.calculator-box .selected-range,
  // .selected-range input {
  //     color: #fff !important;
  //     &:focus{
  //         background: #fff !important;
  //         color: var(--black) !important;

  //     }
  //     &:focus ~ span{
  //         color: var(--black) !important;
  //     }

  // }

  // .selected-range input ~ .selected-range span{
  //     color: red !important
  // }

  // .selected-range{
  //     &:focus{
  //         background-color: red !important;
  //         // & ~ .calc-input-prefix{
  //         //     // background: #fff !important;
  //         //     // color: var(--black) !important;
  //         // }
  //     }
  // }

  // .FT-calculator-box .calc-input-prefix {
  //     &:focus{
  //         background-color: red !important;
  //         // & ~ .calc-input-prefix{
  //         //     // background: #fff !important;
  //         //     // color: var(--black) !important;
  //         // }
  //     }
  // }



  #sort-footer{
    background-color: #000916 !important;
  }

  .performance-date {
    background-color: #0c1738 !important;
    color: #fff !important;
  }

  .FT-small-menu .block-footer ul li a {
    color: #fff !important;
  }

  .FT-small-menu .selected-country {
    color: #fff !important;
  }

  .FT-small-menu .selected-country .dropdown button {
    color: #fff !important;
  }

  .FT-small-menu .theme-options {
    color: #fff !important;
  }

  //   Fund Card
  .compare-blue-icon{
    display: none;
  }
  .compare-white-icon {
    display: block;
  }

  #privacy-back {
    background-color: #09172d !important;
  }

  #bg-form {
    background-color: #09172d !important;

  }

  .FT-comparefund-wrapper table>tr:first-child>th .add-fund-btn>button {
    color: #fff !important;
    border: 1px solid #fff !important;
  }

  .blog-subheadinng {
    color: #fff !important;
  }
  //   kc

  .category-dropdown .dropdown-menu {
    border: 1px solid #fff;
  }

  // --
  .fund-details-footer {
    background-color: #0b1637;
  }

  .fund-expo-date {
    background-color: #13294b;
    border: 1px solid #3c425d;
  }

  .fund-dark-mode-icon {
    display: block !important;
  }

  .fund-light-mode-icon {
    display: none !important;
  }
  .date-cart{
    background-color: #192753 !important;
  }
  .historic-popup {
    background-color: #091830;
  }

  .share-icon {
    filter: brightness(3.5);

  }
  .share-icon2 {
    filter: brightness(3.5);

  }

  .cal-sub-tital {
    color: #fff;
  }

  // about-underline css
  .aboutlinks:hover {
    border-bottom: 2px solid #fff;
  }

  .FT-knowledge .knowledge-center-CTA span {
    color: #FFFFFF;
  }
  .FT-knowledge .knowledge-center-CTA span:hover {
    border-bottom: 2px solid #fff;
  }

  .FT-footer-pages.forms-download .inner-accordion .accordion-item .accordion-header .accordion-button {
    background: #192753 !important;

  }

  .filter-selected-options .option {
    background-color: #09172d !important;
  }

  .filter-selected-options span.active {
    background: var(--blue) !important;
    color: #fff;
  }

}
