.FT {
    &-all-funds {
        background: #F8F8F8;
        padding-top: 0;
        padding-bottom: 66px;

        .container {
            // max-width: 1290px; // old css
            max-width: 1349px;
        }

        .search {
            height: 28px;
            border-radius: 8px;
            border: 1px solid #C4C4C4;
            background: #FFF;
            background: #fff url("/assets/images/fund/search-icon2.svg") no-repeat left 12px center;
            background-size: 15px;
            padding-left: 36px;
            padding-right: 36px;
            color: #000;
            font-size: 0.813rem;
            font-size: 0.813rem;
            font-weight: 400;
            letter-spacing: -0.39px;

            &:focus {
                outline: none;
                box-shadow: none;
                border-color: var(--blue)
            }

            &::placeholder {
                color: #616161;
            }
        }

        .search-result {
            // padding: 8px;
            border-radius: 8px;
            // border: 1px solid #F2F4F7;
            // background: #fff;
            // box-shadow: 0px 0px 5px gray;
            font-size: 1rem;
            font-weight: 500;
            letter-spacing: -0.48px;
            position: absolute;
            z-index: 999;
            margin-top: 10px;
            // opacity: 0;
            // visibility: visible;

            .inner {
                width: 89%;
                border-radius: 8px;
                max-height: 400px;
                overflow-y: auto;
                padding-top: 5px;
                // padding: 8px 16px;
                box-shadow: 0px 0px 5px gray;

                .funds-seach-title{
                    padding: 8px 10px;
                }

            }

            .sub {
                display: inline-block;
                font-size: 0.875rem;
                font-weight: 400;
                letter-spacing: -0.42px;
                color: #595A6C;
                position: relative;
                margin-left: 8px;

                .text-gray {
                    color: #8189A2
                }

                &::before {
                    content: "";
                    display: inline-block;
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background: var(--black);
                    margin-right: 8px;
                }
            }
        }

        .search:focus+.search-result {
            opacity: 1;
            visibility: visible;
        }

        .filter-options {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            font-size: 0.875rem;
            font-weight: 500;
            letter-spacing: -0.42px;


            .btn,
            .form-control,
            .form-select,
            .dropdown-toggle {
                border-radius: 8px;
                border: 1px solid #D2D8EA;
                background-color: #FFF;
                font-size: 0.875rem;
                font-weight: 500;
                letter-spacing: -0.42px;
                height: 38px;
                outline: none;
                box-shadow: none;
            }

            .dropdown {
                .dropdown-menu {
                    padding: 0;
                    border-radius: 8px;
                    background: #FFF;
                    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.12);

                    li {
                        padding: 12px 16px;
                        border-bottom: 1px solid #fff;

                        .dropdown-item {
                            padding: 0;
                            margin: 0;
                            color: #676E76;
                            font-size: 0.875rem;
                            font-weight: 400;
                            letter-spacing: -0.42px;
                            text-align: center;
                        }

                        &:hover,
                        &.selected-option {
                            background: #DDEAFF;

                            .dropdown-item {
                                color: var(--blue)
                            }
                        }

                        &:last-child {
                            border-bottom: 0;
                        }
                    }
                }
            }

            .btn {
                padding: 8px 12px;
                margin-left: 8px;
                position: relative;
                scale: 1;

                img {
                    vertical-align: middle;
                }

                .list-num {

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background: #E24100;
                    color: #FBFBFB;
                    font-size: 0.625rem;
                    font-weight: 700;
                    letter-spacing: -0.3px;
                    position: absolute;
                    top: -5px;
                    right: -5px;

                }

                &:hover {
                    color: var(--blue)
                }
            }

            .form-group {
                flex: 1 0 0%;
                padding-right: 0px;

                .form-control {
                    width: 100%;
                    height: 39px;
                }
            }

        }

        .position-stiky {
            background: #F8F8F8;
            // position: sticky;
            top: 0;
            z-index: 999;
            padding-top: 44px;
        }

        .fund-card-wrapper {
            margin-bottom: 1rem;
            &:last-child {
                margin-bottom: 0 !important;
            }
        }
        .fund-block {
            box-shadow: none;
            border: 1px solid #D2D8EA;
            // z-index: initial;
            scale: initial;
            cursor: pointer;
            &:hover{
                box-shadow:  0px -25px 20px -20px rgba(215, 213, 213, 0.45),
                0px 25px 20px -20px rgba(208, 206, 206, 0.45);
                scale: 1.01;
            }

            // &:last-child {
            //     margin-bottom: 0 !important;
            // }

            h3 {
                a {
                    // transition: all 0.3s ease-in-out;

                    span {
                        // transition: all 0.3s ease-in-out;
                    }

                }
            }

            &:hover {
                h3 {
                    a {
                        span {
                            // color: Blue;
                            // border-bottom: 1px solid var(--blue);;
                        }
                    }
                }
            }
        }

        .funds-container {
            //  padding-right: 13px;
        }

        .filter-title {
            font-size: 2rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.96px;
            color: var(--blue);
            margin-bottom: 26px;
            padding-top: 15px;
            transition: all 0.5s ease-in-out;
        }

        // .fund-filter-block {
        //     @media(min-width:992px) {
        //         position: sticky;
        //         top: 26px;
        //         z-index: 999;

        //         &.position-relative {
        //             z-index: 99999;
        //         }
        //     }

        // }

        .filters {
            border-radius: 12px;
            border: 1px solid #D2D8EA;
            background: #FFF;
            padding: 0;
            width: 279px;

            .inner-filters {
                // height: 550px;
                // overflow-y: auto;
                //
                margin-right: 5px;
                margin-top: 10px;
                padding-right: 5px;
                &::-webkit-scrollbar {
                    width: 6px;

                }

                &::-webkit-scrollbar-thumb {
                    background:#e7e7e7;
                    width: 6px;

                }
            }

            .filter-head {
                padding: 14px 20px;
                background: #DDEAFF;
                color: var(--black);
                font-size: 1.5rem;
                font-weight: 700;
                letter-spacing: -0.72px;
                border-radius: 12px 12px 0 0;
            }

            .accordion {
                padding: 0 10px 0 18px
            }

            .accordion-item {
                margin-top: 20px;
                border: 0;
                border-bottom: 1px solid #D2D8EA;

                .accordion-header {
                    .accordion-button {
                        padding: 0 0 16px 0;
                        outline: none;
                        box-shadow: none;
                        font-size: 1.125rem;
                        font-weight: 600;
                        letter-spacing: -0.54px;
                        color: var(--black);

                        &::after {
                            background-size: 16px;
                        }

                        &:not(.collapsed) {
                            //color: var(--blue);
                            background: none;
                            font-weight: 700;
                        }
                    }
                }

                .accordion-body {
                    padding: 0;
                    padding-bottom: 24px;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 142.5%;
                    letter-spacing: -0.48px;

                    .form-check {
                        // margin-bottom: 15px;
                        margin-bottom: 0;
                        padding-top: 8px;
                        padding-bottom: 8px;
                    }
                }
            }

            .btn {
                width: 100%;
                font-weight: 600;
                letter-spacing: -0.48px;
                padding: 12px
            }

            .more-option-btn {
                font-size: 1rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.48px;
                color: var(--blue);
                background: transparent;
                outline: none;
                box-shadow: none;
                border: 0;
                padding: 12px 0;

                i {
                    font-size: 0.875rem;
                    margin-left: 6px;
                }
            }

            .filter-footer {
                padding: 16px 12px
            }
        }

        .advanced-filter {
            position: fixed;
            top: 0;
            right: 0;
            height: 100%;
            width: 100%;
            z-index: 999;
            background: rgba(0, 0, 0, 0.12);

            .filter-content {
                background: #fff;
                height: 100%;
                width: 100%;
                max-width: 426px;
                box-shadow: -6px 0px 20px 0px rgba(0, 0, 0, 0.07);
                margin-left: auto;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .inner-content {
                    // padding: 30px 36px;
                    padding: 0px 0px;
                    height: calc(100% - 96px);
                    overflow-y: auto;
                }

                h3 {
                    color: var(--black);
                    font-size: 1.5rem;
                    font-weight: 700;
                    // margin-bottom: 34px;
                    margin-top: 10px;
                    margin-left: 19px;
                }

                .accordion-item {
                    border: 0;
                    border-radius: 0;
                    margin-bottom: 24px;

                    .accordion-header {

                        .accordion-button {
                            color: var(--black);
                            font-size: 1.125rem;
                            font-weight: 600;
                            letter-spacing: -0.54px;
                            padding: 0;
                            background: transparent !important;
                            border: 0;
                            border-radius: 0;
                            box-shadow: none;
                        }
                    }

                    .accordion-body {
                        border-bottom: 1px solid #D2D8EA;
                        padding: 16px 0 24px 0;
                        color: #595A6C;
                        font-size: 1.125rem;
                        font-weight: 400;
                        letter-spacing: -0.54px;
                    }
                }

                .small{
                    font-size: 0.875rem;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.42px;    margin-right: -10px;
                    span{

                    }
                }
            }
        }

        .filter-footer {
            background: #F2F2F2;
            padding: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 12px;

            .btn {
                width: 50%;
                height: 47px;
                font-size: 1rem;
                font-weight: 700;

                &.btn-outline-primary {
                    background: #fff;
                }
            }
        }

        .filter-selected-options {
            display: flex;
            align-items: center;

            .clear-filter {
                color: var(--blue);
                font-size: 1rem;
                font-weight: 600;
                letter-spacing: -0.48px;
                margin-right: 48px;
                margin-bottom: 7px;
                cursor: pointer;
            }

            .option {
                border-radius: 40px;
                border: 1px solid #D2D8EA;
                background: #F0F6FF;
                display: inline-block;
                padding: 4px 16px;
                font-size: 0.875rem;
                margin-bottom: 7px;
                margin-right: 8px;

                i {
                    margin-left: 8px;
                }
            }
        }
    }
}
