#page-content.bg-img {
    background-image: url("https://franklintempletonprod.widen.net/content/lbh8nln0im/original/bg-en-in-new-main.png");
}


.FT {
    &-footer-pages {
        padding-top: 41px;
        padding-bottom: 103px;
        color: #595A6C;
        line-height: 142.5%;
        letter-spacing: -0.48px;

        h3 {
            font-weight: 600;
            color: var(--black);
        }

        .text-bold {
            color: var(--black);
        }

        .font-meduim {
            font-size: 1.125rem;
        }

        ul {
            margin-bottom: 8px;
            padding-left: 20px;
        }

        .bg-white {
            border-radius: 8px;
        }

        .nav-pills {
            border-radius: 8px;
            background: #F0F6FF;
            max-width: 342px;
            padding: 24px 16px;
            /*    position: sticky;
            top: 30px;*/

            .nav-link {
                border-radius: 0;
                border-bottom: 1px solid #DDEAFF;
                padding: 8px 8px 16px 8px;
                margin-bottom: 24px;
                display: block;
                width: 100%;
                color: var(--black);
                font-size: 1.125rem;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.54px;

                &:last-child {
                    margin-bottom: 0;
                    border-bottom: 0;
                }

                &.active {
                    color: var(--blue);
                    font-weight: 700;
                    border-bottom: 4px solid var(--blue);
                    background: transparent;
                }
            }
        }

        .media-contact {
            margin-top: 32px;
            padding: 32px;
            border-radius: 8px;
            background: #F0F6FF;
            margin-bottom: 54px;

            h3 {
                color: #595A6C;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.72px;
            }

            .contact-box {
                padding: 32px;
                border-radius: 7px;
                background: #FFF;
                box-shadow: 0px 2px 11.81px 0px rgba(0, 0, 0, 0.10);


                h3 {
                    color: #09172D;
                    font-weight: 600;
                }

                p {
                    color: #595A6C;
                    font-weight: 400;
                    line-height: 142.5%;
                    letter-spacing: -0.48px;
                }

                .d-flex {
                    font-size: 1.125rem;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -0.54px;

                    &:not(:last-child) {
                        margin-bottom: 12px;
                    }

                    img {
                        margin-right: 8px;
                    }
                }
            }
        }

        .reports-box {
            padding: 8px 16px;
            border-radius: 8px;
            border: 1px solid #D2D8EA;

            table {

                th,
                td {
                    padding: 16px;
                }

                thead {
                    tr {
                        th {
                            background: #F0F6FF;
                            font-size: 1.125rem;
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            font-size: 1rem;
                            color: var(--black);
                        }
                    }
                }
            }
        }

        .features-box {
            border-radius: 8px;
            background: #FFF;
            margin-bottom: 32px;

            /* new */
            box-shadow: 0px 4px 20px 0px rgba(23, 55, 163, 0.16);
            padding: 48px 54px;

            .box-img {
                border-radius: 8px;
            }

            .row {
                align-items: center;

                .col-lg-7 {
                    @media(min-width:992px) {
                        padding-left: 50px;
                    }
                }

                &:nth-child(even) {
                    flex-direction: row-reverse;

                    .col-lg-7 {

                        @media(min-width:992px) {
                            padding-left: 15px;
                            padding-right: 50px;
                        }
                    }
                }

                &:not(:last-child) {
                    margin-bottom: 24px;

                }
            }

            .content {
                color: #595A6C;
                font-size: 1.125rem;
                font-weight: 400;
                line-height: normal;
                line-height: 1.4;
                letter-spacing: -0.54px;

                h3 {
                    display: flex;
                    align-items: center;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.72px;
                    margin-bottom: 24px;

                    span {
                        display: inline-flex;

                        &:not(.box-icon) {
                            width: calc(100% - 60px);
                            padding-left: 20px;
                        }
                    }

                    .box-icon {
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        background: #F0F6FF;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }

        .feedback-box {
            color: #8189A2;
            font-size: 1.125rem;
            font-weight: 500;
            letter-spacing: -0.54px;
            border-radius: 8px;
            box-shadow: 0px 4px 20px 0px rgba(23, 55, 163, 0.16);
            background: #fff;
            padding: 24px;
            width: 100%;
            max-width: 1090px;
            margin: 75px auto 0 auto;

            .form-group {
                margin-bottom: 39px;
            }

            .form-label {
                color: #595A6C;
                font-size: 1.25rem;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.6px;
                display: block;
                margin-bottom: 1rem;

                .required {
                    color: #E24100;
                }
            }

            .form-control {
                border-radius: 4px;
                border: 1px solid #ACACAC;
                height: 53px;
                padding: 16px 8px;

                &::placeholder {
                    color: #8189A2;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.32px;
                }
            }

            textarea.form-control {
                height: 101px;
            }

            .upload-box {
                border-radius: 4px;
                border: 1px dashed var(--Fuchsia, #B7B7B7);
                padding: 22px 23px 20px 32px;
                position: relative;

                .form-control {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    margin: 0;
                    padding: 0;
                    left: 0;
                    top: 0;
                    opacity: 0;
                    //visibility: hidden;
                }

                span {
                    display: inline-flex;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                }

                .img-icon {
                    margin-right: 32px;
                    width: 45px;
                    height: 45px;
                    border-radius: 4px;
                    background: rgba(217, 217, 217, 0.30);
                }

                .img-name {
                    font-size: 1rem;
                    max-width: 300px;
                }

                .btn {
                    padding: 12px 30px;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 142.5%;
                    letter-spacing: -0.48px;
                }
            }

            .evaluation-box {
                .form-check {
                    margin: 0;
                    padding: 0;

                    .form-check-input {
                        width: 100%;
                        height: 100%;
                        margin: 0;
                        padding: 0;
                        left: 0;
                        top: 0;
                        opacity: 0;
                        visibility: hidden;
                    }

                    .form-check-label {
                        cursor: pointer;
                    }
                }
            }

            .btn-primary {
                width: 130px;
                // padding: 12px 30px;

            }
        }

        @media(min-width:768px) {
            .accordion-item {
                .accordion-header {
                    display: none;
                }

                .collapse {
                    display: block;
                }

                .accordion-body {
                    // padding: 32px 24px;
                }
            }
        }


        &.forms-download {
            padding-top: 56px;
            padding-bottom: 120px;

            .form-tabs {
                align-items: center;

                .nav-tabs {
                    margin: 0;
                    padding: 0;

                    .nav-link {
                        padding: 16px 32px;
                        font-size: 1.125rem;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: -0.54px;
                    }
                }

                .form-group {
                    flex: 1 0 0;
                    padding-left: 44px;

                    .form-control {
                        border-radius: 8px;
                        border: 1px solid #C4C4C4;
                        background: #FFF;
                        padding: 8px 12px;
                        background: #fff url("/assets/images/fund/search-icon2.svg") no-repeat left 12px center;
                        background-size: 15px;
                        padding-left: 36px;
                        font-size: 0.875rem;
                        outline: none;
                        box-shadow: none;

                        &:focus {
                            border-color: var(--blue);
                        }
                    }
                }
            }

            .nav-pills {
                @media(min-width:992px) {
                    max-width: 300px;
                }
            }

            .filter-box {
                flex-wrap: wrap;
                gap: 16px;

                .text-big {
                    color: #595A6C;
                    letter-spacing: -0.6px;
                }

                .filter-date {
                    display: flex;
                    flex: 1 0 0;
                    align-items: center;
                    padding: 8px 16px;
                    border-radius: 9px;
                    background: #F0F6FF;

                    span {
                        color: #595A6C;
                        font-size: 0.875rem;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: -0.42px;
                    }

                    form {
                        display: flex;
                        align-items: center;

                        .form-group,
                        .date-group {
                            margin-left: 12px;
                            width: 192px;
                            height: 40px;
                            background: #fff;
                            padding: 10px;
                        }

                        .btn {
                            width: 117px;
                            margin-left: 24px;
                        }
                    }

                    .foorm-control {
                        border: 0
                    }

                    @media(max-width:1100px) {
                        flex: 0 0 100%
                    }
                }
            }

            .category-btns {
                padding-top: 32px;
                padding-bottom: 32px;

                ul {
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    list-style-type: none;
                    display: flex;
                    overflow: auto;
                    cursor: pointer;

                    &::-webkit-scrollbar {
                        display: none;
                        /* for Chrome, Safari and Opera */
                    }

                    li {
                        padding: 12px 32px;
                        border-radius: 32px;
                        background: #F0F6FF;
                        font-size: 1rem;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: -0.48px;
                        color: var(--blue);
                        min-width: fit-content;
                        margin-right: 16px;
                        border: 1px solid #fff;

                        &.active {
                            background: var(--blue);
                            color: #fff;
                        }
                    }
                }
            }

            .inner-accordion {
                .accordion-item {
                    margin-bottom: 20px;
                    border: 1px solid #D2D8EA;
                    border-radius: 2px;

                    .accordion-header {
                        display: initial;

                        .accordion-button {
                            border-radius: 8px;
                            background: transparent;
                            border: 0 !important;
                            box-shadow: none;
                            padding: 11px 16px;

                            &::after {
                                width: 16px;
                                height: 16px;
                                background-size: 16px;
                            }
                        }
                    }

                    .collapse {
                        display: initial;

                        &:not(.show) {
                            display: none;
                        }
                    }

                    .accordion-body {
                        // padding: 32px 24px;
                        border: 0;

                        .reports-box {
                            padding: 0;
                            border: 0;
                        }
                    }
                }
            }

            table {

                img {
                    max-height: 21px;
                }

                tbody tr td {
                    padding: 10px 14px;
                }
            }
        }

        &.faq {
            .bg-md-white {
                @media(min-width:768px) {
                    background: #fff;
                    box-shadow: 0px 4px 20px 0px rgba(23, 55, 163, 0.16);
                    padding: 24px 34px 51px 24px;
                }
            }

            .inner-accordion {

                .accordion-item {
                    border: 0;
                    border-radius: 0;
                    background: transparent;
                    box-shadow: none !important;
                    padding: 0;

                    .accordion-header {
                        .accordion-button {
                            border-radius: 8px;
                            border: 1px solid #D2D8EA !important;
                            background: #fff;
                            padding: 16px 24px;
                            font-weight: 600;

                            &::after {
                                width: 28px;
                                height: 20px;
                                background-image: url("/assets/images/home/plus-icon.svg");
                            }

                            &:not(.collapsed)::after {
                                background-image: url("/assets/images/home/minus-icon.svg");
                            }
                        }
                    }

                    .accordion-body {
                        margin-top: 16px;
                        border-radius: 8px;
                        border: 1px solid #D2D8EA;
                        background: #FFF !important;
                        color: #616161;
                        padding: 16px;

                        a {
                            color: #2769ff;
                        }
                    }
                }
            }

        }
    }
}
