.FT {
  &-general-filter {
    color: var(--black);
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 999999;
    background: rgba(0, 0, 0, 0.12);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;

    .filter-box {
      background: #fff;
      height: 100%;
      width: 100%;
      max-width: 426px;
      box-shadow: -6px 0px 20px 0px rgba(0, 0, 0, 0.07);
      margin-left: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      transform: translateX(500px);
      transition: all 0.4s ease-in-out;



      .filter-content {
        padding: 30px 36px;
        height: calc(100% - 96px);
        overflow-y: auto;
      }

      h3 {
        color: var(--black);
        font-size: 1.5rem;
        font-weight: 700;
        margin-bottom: 34px;
      }

      .text-bold {
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.54px;
      }

      .filter-options {
        margin-bottom: 26px;
      }

      .filter-footer {
        background: #F2F2F2;
        padding: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;

        .btn {
          width: 50%;
          height: 47px;
          font-size: 1rem;
          font-weight: 700;

          &.btn-outline-primary {
            background: #fff;
          }
        }

      }
    }

    &.show {
      opacity: 1;
      visibility: visible;

      .filter-box {
        transform: translateX(0px);
      }
    }
  }

  &-hero {
    &.inner-page {

      .FT-title-medium {
        margin-bottom: 30px;
      }

      .carousel {
        .carousel-item {
          height: auto;
          min-height: 432px;
          background-position: center center !important;

          .container {
            padding-top: 40px;
            padding-bottom: 80px;
          }

          p {
            font-size: 1rem;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.48px;
            max-width: 747px;
            margin-bottom: 54px;
          }

          .btn {
            width: 100%;
            max-width: 390px;
            padding: 12px 28px;
            font-size: 1.125rem;
            font-family: "Inter";
            font-weight: 500;
          }

          .row {
            font-size: 1.125rem;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.54px;
          }
        }
      }
    }
  }

  &-ad-box {
    background: url("https://franklintempletonprod.widen.net/content/osl9iajhwx/original/ad-bg.svg") no-repeat center center;
    background-size: cover;
    color: #fff;
    padding: 28px 36px;
    border-radius: 12px;

    //border: 2px solid #D2D8EA;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      list-style-type: none;

      li {
        display: inline-block;
        margin-right: 40px;
        font-size: 1.125rem;
        letter-spacing: -0.54px;

        strong {
          margin-right: 4px;
          font-weight: 600;
        }
      }
    }

    .btn {
      padding: 16px 28px;
      width: 100%;
      max-width: 207px;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      font-family: "Inter";
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
    }
  }

  &-knowledge {
    padding-top: 44px;
    padding-bottom: 24px;
    background: url("https://franklintempletonprod.widen.net/content/lbh8nln0im/original/bg-en-in-new-main.png") center center;

    .FT-blocks-nav {
      margin-bottom: 40px;
    }

    .slick-slider {
      .slick-list {
        margin-left: -16px;
        // overflow: visible;
      }

      .slick-slide {
        padding: 16px;
      }

      .slick-arrow {
        top: 30%;

        &.right-arrow {
          right: 5px;
        }

        &.left-arrow {
          left: 5px;
        }
      }
    }

    h3 {
      font-weight: 700;

      a {
        font-size: 1rem;
        font-weight: 600;

        i {
          font-size: 0.875rem;
          margin-left: 6px;
        }
      }

    }

    .knowledge-box {
      padding: 24px;
      border-radius: 8px;
      background: #fff;
      box-shadow: 0px 4px 60px 0px rgba(16, 27, 66, 0.14);
      margin-bottom: 60px;

      .filter-block {
        .search {
          width: 523px;
          height: 39px;
          padding: 8px 44px 8px 36px;
          border-radius: 8px;
          border: 1px solid #C4C4C4;
          background: #fff url('/assets/images/fund/search-icon2.svg') no-repeat left 12px center;
          color: #616161;
          font-size: 0.875rem;
          font-weight: 500;
          line-height: normal;
        }

        .btn,
        .dropdown-toggle {
          border-radius: 8px;
          border: 1px solid #D2D8EA;
          background-color: #FFF;
          font-size: 0.875rem;
          font-weight: 500;
          letter-spacing: -0.42px;
          height: 38px;
          outline: none;
          box-shadow: none;
          padding: 8px 12px;
          color: #595A6C;

          img {
            vertical-align: middle
          }

        ;
        }

        .dropdown {
          display: inline-block;

          .dropdown-toggle {
            width: 162px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &::after {
              border: 0;
              vertical-align: middle;
              width: 6px;
              height: 9.6px;
              content: "";
              background-image: url("/assets/images/knowledge-center/arrow-down.svg");
              background-repeat: no-repeat;
              background-size: 6px 9.6px;
              transition: transform 0.2s ease-in-out;
            }
          }

          .dropdown-menu {
            padding: 0;
            border-radius: 8px;
            background: #FFF;
            box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.12);

            li {
              padding: 12px 16px;
              border-bottom: 1px solid #fff;

              .dropdown-item {
                padding: 0;
                margin: 0;
                // color: #676E76;
                font-size: 0.875rem;
                font-weight: 400;
                letter-spacing: -0.42px;
                // text-align: center;
              }

              &:hover,
              &.selected-option {
                background: #DDEAFF;

                .dropdown-item {
                  color: var(--blue)
                }
              }

              &:last-child {
                border-bottom: 0;
              }
            }
          }
        }

        .show-dark-mode {
          display: none;
        }
      }

      .category-btns {

        ul {
          list-style: none;
          list-style-type: none;
          padding: 0;
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: auto;
          gap: 16px;

          &::-webkit-scrollbar {
            display: none;
            /* for Chrome, Safari and Opera */
          }

          li {
            display: inline-block;
            min-width: 149px;
            padding: 12px 32px;
            color: var(--blue);
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.54px;
            border-radius: 32px;
            text-align: center;
            cursor: pointer;
            background: #F0F6FF;

            &.active {
              background: var(--blue);
              color: #fff;
            }
          }
        }
      }



      .d-flex {
        a {
          // font-size: 1rem;
          // font-weight: 600;

          i {
            font-size: 0.875rem;
            margin-left: 6px;
          }
        }
      }



      &.categories {
        .FT-ad-box {
          margin-bottom: 40px;
        }

        .filter-block {
          margin-bottom: 34px;
        }

        .category-btns {
          margin-bottom: 32px;
        }

        h3 {
          margin-bottom: 32px;
        }

        .category-block {
          margin-bottom: 40px;
        }
      }

      &.academy {
        .inner-box {
          background: #DDEAFF url("https://franklintempletonprod.widen.net/content/jddx2corek/original/academy-bg2.png") no-repeat bottom -20px right;

          padding: 28px 36px;
          border-radius: 12px;
        }

        .top-row {
          padding-top: 33px;
          margin-bottom: 72px;
        }

        .btn {
          padding: 16px 28px;
          font-family: "Inter";
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 500;
          line-height: 155.556%;
        }

        .text-gray {
          color: #8189A2;
          max-width: 848px;
          letter-spacing: -0.48px;
        }

        .academy-box {
          width: 100%;
          max-width: 695px;
          border-radius: 8px;
          background: rgba(255, 255, 255, 1);
          box-shadow: 0px 2px 11.81px 0px rgba(0, 0, 0, 0.10);
          margin-bottom: 90px;

          .row {
            margin: 0;
          }

          .col-md-6 {
            padding: 40px 32px;
          }

          h4 {
            color: #09172D;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.54px;
            margin-bottom: 20px;
          }

          ul {
            padding: 0;
            margin: 0;
            list-style-type: none;
            list-style: none;

            li {
              margin-bottom: 16px;
              color: #595A6C;
              font-size: 1rem;
              font-weight: 500;
              line-height: normal;
              letter-spacing: -0.48px;

              img {
                margin-right: 12px;
              }
            }
          }

          .btn-white {
            border: 0;
            color: #3769FF;
            text-align: center;
            font-size: 1rem;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.48px;
            padding: 7px 8px;
            width: 100%;
            border-radius: 0px 0px 8px 8px;
            background: #fff;
            scale: 1 !important;
            box-shadow: none !important;

            i {
              vertical-align: middle;
              margin-left: 8px;
              font-size: 0.875rem;
            }

            &:hover {
              color: #fff;
              background: #3769FF;
            }
          }
        }
      }

      &.insights {
        .FT-ad-box {
          margin-bottom: 28px;
        }

        h3 {
          margin-bottom: 28px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      &.inner-categories {
        .category-card.big-card {
          align-items: center;
          padding: 24px 40px;

          .card-img {
            width: 243px;
            height: 178.676px;

            img {
              object-fit: cover;
            }
          }

          .card-body {
            width: calc(100% - 243px);
            padding: 0 0 0 32px;

            .d-flex {
              font-size: 1.063rem;
            }
          }
        }
      }
    }
    .knowledge-center-CTA {
      span:hover{
        border-bottom: 2px solid;
      }
    }
    .category-card:hover {
      box-shadow: 0px 0px 25px rgba(16, 27, 66, 0.24);
      scale: 1.01;
      z-index: 1;
      position: relative;
    }

  }

  &-knowledge-tabs {
    padding-top: 40px;
    padding-bottom: 40px;
    background: #F0F6FF;

    .container {
      max-width: 1297px;
    }

    .FT-title-small {
      margin-bottom: 30px;
    }

    .nav-pills {
      padding: 16px;
      border-radius: 8px;
      background: #fff;
      box-shadow: 0px 4px 20px 0px rgba(23, 55, 163, 0.16);
      width: 326px;

      .nav-link {
        width: 100%;
        display: flex;
        padding: 32px 42px;
        flex-direction: column;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #D2D8EA;
        background: #FFF;
        color: #595A6C;
        font-size: 1.5rem;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.72px;

        &:not(:last-child) {
          margin-bottom: 24px;
        }

        img {
          margin-bottom: 20px;
        }

        &.active {
          border-bottom: 4px solid var(--blue);
          background: #F0F6FF;
        }
      }
    }

    .tab-content {
      padding: 24px 54px;
      border-radius: 8px;
      border: 1px solid #D2D8EA;
      background: #fff;
      height: 100%;

      h3 {
        color: #09172D;

        a {
          font-size: 1rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.48px;

          i {
            font-size: 0.875rem;
            margin-left: 8px;
          }
        }
      }

      .tab-pane {
        height: 100%;
      }

      .tab-form {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .form-group {
          margin-bottom: 20px;
        }

        .btn {
          width: 100%;
          padding: 16px 14px;
          font-family: "Inter";
          font-size: 1rem;
          font-weight: 400;
          line-height: 150%;
        }
      }

      .compareCardBox {
        border-radius: 8px;
        border: 4px solid #fff;
        background: #F3F8FF;
        box-shadow: 0px 2px 11.81px 0px rgba(0, 0, 0, 0.10);
      }
    }
  }

  &-inner-banners {
    height: 432px;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .content-box {
      max-width: 772px;
    }

    h2 {
      font-size: 3rem;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.96px;
    }
  }

  &-article-content {
    padding-top: 15px;
    padding-bottom: 40px;

    .container {
      max-width: 1215px
    }

    .article-title {
      .text-meduim {
        font-size: 1.125rem;
        line-height: normal;
        letter-spacing: -0.54px;
      }

      h3, h1 {
        color: #323F4F;
        font-size: 1.75rem;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.56px;
      }

      .text-small {
        color: #595A6C;
        font-size: 0.875rem;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.42px;

        .tag {
          color: var(--blue);
          position: relative;
          margin-left: 6px;

          &::before {
            content: "";
            display: inline-block;
            width: 5.124px;
            height: 5.124px;
            border-radius: 50%;
            background: #595A6C;
            margin-right: 6px;
            vertical-align: 2.6px;
          }
        }
      }

      .icons {
        color: #595A6C;
        font-size: 1.125rem;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.54px;

        .card-icons {
          color: var(--blue);
        }
      }
    }

    .article-img-block {
      position: relative;
      margin-bottom: 35px;

      &.audio-img-block {
        .article-img {
          height: 325px;
          object-fit: cover;
        }
      }

      .audio-block {
        position: absolute;
        bottom: 20px;
        left: calc(50% - 355px);
        border-radius: 8px;
        background: #FFF;
        display: inline-flex;
        padding: 12px 16px;
        align-items: center;
        font-size: 1rem;
        font-weight: 400;
        line-height: 142.5%;
        letter-spacing: -0.48px;

        .btn {
          padding: 0;
          box-shadow: none !important;
          background: transparent;
        }

        .progress {
          width: 382px;
          height: 6px;
          background: #D2D8EA;
          border-radius: 20px;
          margin: 0 9px
        }
      }
    }

    .video-img-block {
      position: relative;

      .play-video-btn {
        padding: 0;
        background: transparent;
        border: 0;
        position: absolute;
        top: calc(50% - 21px);
        left: calc(50% - 21px);

        img {
          width: 55px;
        }
      }
    }

    .article-language {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        margin-right: 40px;
      }

      .btn {
        padding: 16px 28px;
        color: #fff;
        font-family: "Inter";
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 500;
        line-height: 155.556%;
        border-radius: 8px;
        border: 1px solid #E24100;
        background: #E24100;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        &:hover,
        &:focus {
          background: var(--blue);
          border-color: var(--blue);
        }
      }

      .languages-box {

        span,
        .form-check {
          min-width: fit-content;
        }

        .languages {
          display: inline-flex;
          align-items: center;
          overflow-x: auto;

          &::-webkit-scrollbar {
            height: 0;
            width: 0;
            border-radius: 6px;
          }
        }
      }
    }

    .toggle-content-box {
      display: flex;

      max-width: fit-content;
      padding: 6px 8px;
      border-radius: 8px;
      border: 1px solid #D2D8EA;
      background: #FFF;

      span {
        padding: 6px 12px;
        border-radius: 8px;
        color: #8189A2;
        font-size: 0.875rem;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.42px;
        cursor: pointer;

        &.active {
          background: var(--blue);
          color: #fff;
        }
      }
    }

    .content {
      color: #595A6C;
      font-family: "Inter";
      font-size: 1rem;
      line-height: 150%;

      h3 {
        font-size: 1.75rem;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.84px;
        color: var(--black);
      }

      .btn {
        font-family: "Inter";
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 155.556%;
        padding: 16px 28px;

        img {
          vertical-align: middle;
          margin-right: 8px;
        }

        &.btn-transparent {
          border: 0;

        }

        &-light-primary {
          border: 1px solid var(--blue);
          color: var(--blue);
          background: #F0F6FF;

          img {
            max-width: 50px;
            margin: 0;
            margin-right: -28px;

            &:last-child {
              margin-right: 8px;
            }
          }
        }
      }

    }
  }

  &-related-tags {
    background: #F0F6FF;
    padding-top: 48px;
    padding-bottom: 48px;

    .container {
      max-width: 1215px;
    }

    .tags-list {
      padding: 0;
      margin: 0;
      list-style-type: none;
      list-style: none;
      color: #595A6C;
      font-size: 1.125rem;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.42px;
      margin-bottom: 40px;

      li {
        display: inline-block;

        &:not(:last-child) {
          margin-right: 40px;
        }
      }

      .tag {
        color: var(--blue);
        position: relative;
        margin-left: 13px;
        cursor: pointer;

        &::before {
          content: "";
          display: inline-block;
          width: 5.124px;
          height: 5.124px;
          border-radius: 50%;
          background: #595A6C;
          margin-right: 13px;
          vertical-align: 3px;
        }

        & .knowledge-related-heading-cta:hover {
          border-bottom: 2px solid;
        }
      }
    }
  }

  &-category-ad {
    background: #F0F6FF url("https://franklintempletonprod.widen.net/content/dcutpmmaox/original/category-ad.png") no-repeat right center;
    background-size: cover;
    padding: 40px 8px;
    border-radius: 8px;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.10);

    .d-flex {
      img {
        margin-right: 14px;
      }

      .text-big {
        font-weight: 600;
      }

      .text-meduim {
        margin-bottom: 32px;
      }

      .btn {
        padding: 10px 32px;
        font-weight: 500;
      }
    }
  }

  &-podcast {
    .category-card {
      box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.10);
    }

    .podcast-details {
      margin-top: -50px;
      position: relative;
      margin-bottom: 60px;

      .category-card.big-card {
        padding: 40px 60px;

        h2 {
          color: var(--black);
          font-size: 2.25rem;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -1.08px;

          small {
            color: var(--Grey, #8189A2);
            font-size: 1.125rem;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.54px;
          }
        }

        .subtitle {
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.54px;
        }

        .card-icons {
          .text-gray {
            color: var(--black)
          }

          display: inline-block;
        }

        .btn {
          width: 156px;
          height: 52px;
          margin-left: 12px;

          img {
            margin-right: 12px;
          }
        }

        .short-descriptiton {
          margin-top: 12px;
        }
      }
    }

    .podcast-list {
      .category-card {
        margin-bottom: 28px;
        padding: 40px;

        .card-img {
          width: 243px;
          height: 100%;
        }

        .card-body {
          padding-left: 32px;
          width: 100%;
          flex: 1 0 0;

          h3 {
            display: inline-block;
            color: var(--black);
          }

          .tag {
            display: inline-block;
            border-radius: 80px;
            background: #F0F6FF;
            margin-left: 12px;
            padding: 7px 24px;
            color: var(--black);
            font-size: 0.875rem;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.42px;
            vertical-align: 4px;
          }

          .short-descriptiton {
            margin-top: 16px;
          }

          .audio-play-option {
            margin-top: 28px;
            display: inline-flex;
            align-items: center;
            color: #595A6C;
            font-size: 0.875rem;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.42px;
            padding: 12px 20px;
            border-radius: 8px;
            background: #F0F6FF;

            .btn {
              padding: 0;
              background: transparent;
              border: 0;
              margin-right: 16px;

              &::after {
                display: none;
              }
            }

            .progress {
              margin-right: 20px;
              margin-left: 20px;
              height: 4px;
              background: #DCDCDC;
              min-width: 427px;
            }
          }
        }
      }
    }

    .FT-calculator-accordion {
      padding-top: 68px;
      padding-bottom: 124px;

      .accordion {
        .accordion-item {
          .accordion-button {
            padding-bottom: 8px;

            &::after {
              display: none;
            }
          }

          .accordion-collapse {
            .accordion-body {
              background: transparent;
              box-shadow: none;
              border: 0;
              color: #8189A2;

              ul {
                li {
                  margin-bottom: 20px;

                  strong {
                    display: block;
                    margin-bottom: 12px;
                    font-weight: 500;
                    color: var(--black);
                  }

                  a {
                    color: var(--blue);
                  }
                }
              }
            }
          }
        }
      }

    }
  }

  &-glossary {
    padding-top: 42px;
    padding-bottom: 120px;

    .FT-title-medium {
      margin-bottom: 38px;
    }

    .filter-box {
      border-radius: 8px;
      border: 1px solid #D2D8EA;
      background: #fff;
      width: 100%;
      max-width: 310px;
      min-height: 100%;

      .filter-head {
        padding: 14px 20px;
        border-radius: 8px 8px 0px 0px;
        background: #DDEAFF;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.72px;
      }

      .filter-body {
        padding: 24px 20px 20px 20px;

        .form-check {
          margin: 4px;
          padding: 0;
          position: relative;

          .form-check-input {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            opacity: 0;
            visibility: hidden;
          }

          .form-check-label {
            padding: 8px 12px;
            border-radius: 4px;
            background: #F0F6FF;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
          }

          .form-check-input:checked[type=checkbox]+.form-check-label,
          .form-check-input:checked[type=radio]+.form-check-label {
            background: var(--blue);
            border-color: var(--blue);
            color: #fff;
          }
        }
      }
    }

    .search-group {
      width: 100%;
      max-width: 617px;
      margin-right: 16px;
      margin: 0;

      .form-control {
        border-radius: 8px;
        border: 1px solid #C4C4C4;
        background: #fff;
        padding: 8px 12px;
      }
    }

    .toggle-filter {
      color: var(--black);

      img {
        margin-top: -3px;
      }
    }

    .glossary-box {
      max-height: 707px;
      overflow-y: auto;
      padding-right: 20px;

      .letter-title {
        color: #000;
        font-size: 2.813rem;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -1.44px;
      }
      .dropdown{
        .dropdown-toggle{
          background: transparent;
          border:0;
          padding: 0;
          &::after{
            display: none;
          }
        }
        .dropdown-menu {
          li{
            padding-left:0;
            img{
              margin-right: 6px;
            }
          }
        }
      }
    }
  }
}

.search-result {
  // padding: 8px;
  border-radius: 8px;
  // border: 1px solid #F2F4F7;
  // background: #fff;
  // box-shadow: 0px 0px 5px gray;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.48px;
  position: absolute;
  z-index: 999;
  margin-top: 10px;
  // opacity: 0;
  // visibility: visible;

  .inner {
    width: 89%;
    border-radius: 8px;
    background: #fff;
    max-height: 400px;
    overflow-y: auto;
    padding-top: 5px;
    // padding: 8px 16px;
    box-shadow: 0px 0px 5px gray;

    .funds-seach-title{
      padding: 8px 10px;
    }

  }

  .sub {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: -0.42px;
    color: #595A6C;
    position: relative;
    margin-left: 8px;

    .text-gray {
      color: #8189A2
    }

    &::before {
      content: "";
      display: inline-block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: var(--black);
      margin-right: 8px;
    }
  }
}

.search:focus+.search-result {
  opacity: 1;
  visibility: visible;
}
