// $black:#3C425D;
// $dark-gray:#676E76;
// $gray:#8189A2;
// $light-gray:#F5F5F5;
// $red:#FE3131;
// $blue:#3769FF;
// $light-blue:#557FFF;
// $sky-blue:#DDEAFF;
// $teal:#00847D;
// $gradient:linear-gradient(90.29deg, #583EE7 26.99%, #0EA0E6 50.09%, #00B6AE 74.69%);

/*** dark mode colors ****/
// $dark-body-bg:#091830;
// $md-dark:#212529;
// $dark-box-bg:#13294B;

:root {
    --mainContentMargin: 126px;
    --mainContentMobileMargin : 97px;
    --black:#3c425d;
    --dark-gray:#676e76;
    --black-Figma:#0C1738;
    --gray:#8189a2;
    --light-gray:#f5f5f5;
    --red:#fe3131;
    --blue:#3769ff;
    --light-blue:#557fff;
    --sky-blue:#ddeaff;
    --teal:#00847d;
    --gradient:linear-gradient(90.29deg, #583EE7 26.99%, #0EA0E6 50.09%, #00B6AE 74.69%);

/*** dark mode colors ****/
    --dark-body-bg:#091830;
    --md-dark:#212529;
    --dark-box-bg:#13294B;
}
