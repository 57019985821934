/*** home page sections style *****/
.FT {

    &-locations,
    &-financial,
    &-guide,
    &-latest-updates,
    &-invest-form {
        background: url("https://franklintempletonprod.widen.net/content/lbh8nln0im/original/bg-en-in-new-main.png") center center;
    }

    &-updates-banners {
        position: relative;
        transition: all 0.8s ease;
        .accordion-button {
            padding: 12px 0;
            outline: none !important;
            box-shadow: none;
            color: var(--red);
            font-weight: 600;
            font-size: 1.125rem;
            line-height: 24px;
            border-radius: 0;

            img {
                margin-right: 12px;
            }

            &::after {
                display: none;
            }

            .container-fluid {
                padding-left: 30px;
                padding-right: 30px;
            }

            .container,
            .container-fluid {
                position: relative;
                display: flex;
                align-items: center;

                &::after {
                    flex-shrink: 0;
                    width: 1.25rem;
                    height: 1.25rem;
                    margin-left: auto;
                    content: "";
                    background-image: url("/assets/images/home/arrow-down-gradient2.svg");
                    background-repeat: no-repeat;
                    background-size: 1.25rem;
                    transition: transform .2s ease-in-out;
                    transform: rotate(-180deg);
                }
            }

            &:not(.collapsed) {
                background: #E2E9FF;

                .container-fluid {
                    &::after {
                        transform: rotate(0deg);
                    }
                }
            }

            &.close-btn {
                background: transparent !important;
                width: 68px;
                height: 68px;
                padding: 0;
                position: absolute;
                bottom: -26px;
                left: calc(50% - 82px);
                z-index: 9;
                transition: all 0.3s ease-in-out;

                img {
                    margin: 0;
                    padding: 0;
                    width: 100%;
                }

                &.collapsed {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }

        .accordion-item {
            border: 0;
            //margin-top: 5px;



            .accordion-body {


                .carousel {

                    .carousel-control-next,
                    .carousel-control-prev {
                        width: 47px;
                        height: 47px;
                        border-radius: 50%;
                        background: #FFFFFF;
                        color: var(--blue);
                        box-shadow: 0px 2.33333px 15.1667px rgba(55, 105, 255, 0.3);
                        opacity: 1;
                        top: calc(50% - 23.5px);
                        transition: all 0.3s ease-in-out;

                        i {
                            font-size: 2.5rem;
                        }

                        &:hover {
                            background: var(--blue);

                            i {
                                color: #fff;
                            }
                        }
                    }

                    .carousel-control-next {
                        right: 24px
                    }

                    .carousel-control-prev {
                        left: 24px
                    }

                    .carousel-item {

                        height: 270px;

                        img {
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

    &-hero {
        position: relative;

        .carousel {
            .carousel-item {
                height: 500px;
                background-size: cover !important;
                background-position: right 0 center !important;

                .container {
                    height: 100%;
                    /* display: flex;
                    flex-direction: column;
                    justify-content: center;*/
                    padding-top: 50px;
                    padding-left: 106px;
                    padding-right: 95px;
                    max-width: 100%;
                }

                img {
                    /* height: 100%;
                    object-fit: cover;*/
                }

                h2 {
                    font-weight: 700;
                    font-size: 3rem;
                    line-height: 55px;
                    letter-spacing: -0.96px;
                    margin-bottom: 24px;

                    img {
                        margin-right: 20px;
                        vertical-align: middle;
                    }
                }

                ul {
                    font-family: "Inter";
                    font-size: 1.25rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 54.6px;
                    letter-spacing: -0.6px;
                    margin-bottom: 30px;
                }

                p {
                    font-size: 1.75rem;
                    font-weight: 400;
                    letter-spacing: -0.56px;
                    max-width: 640px;
                }

                .btn-white {
                    background: #fff;
                    color: var(--blue);
                    padding: 12px 40px;
                    font-size: 1.333rem;
                    font-weight: 600;
                    line-height: 32px;
                }

                .carousel-caption {
                    padding: 0;
                    left: 108px;
                    margin: 0;
                    right: auto;
                    text-align: initial;
                    top: 160px;
                    max-width: 640px;


                }

                &.carousel-with-content {
                    .container {
                        padding-top: 50px;
                    }
                }
            }

            .carousel-control-prev,
            .carousel-control-next {
                opacity: 1;
                width: 40px;
                i {
                    // padding:4px;
                    font-size: 2.5rem;
                    background: white;
                    line-height: 0;
                    border-radius: 50%;
                    color: blue;
                    // font-size: 2.5rem;
                    line-height: 0;
                    // color: #fff
                    &:hover {
                        background: var(--blue);
                        color: #fff;
                    }
                }

                &:hover {
                    // background: var(--blue)
                }
            }

            .carousel-control-prev {
                left: 24px
            }

            .carousel-control-next {
                right: 24px;
            }
        }

        .page-theme {
            width: 110px;
            position: absolute;
            z-index: 9;

            right: 120px;
            top: 1px;
            letter-spacing: -0.03em;

            .font {
                color: #444444;
                font-weight: 600;
                background: #fff;

                .row {
                    margin: 0;
                    align-items: center;
                }

                .small {
                    font-size: 0.875rem;
                    line-height: 18px;
                }

                .medium {
                    font-size: 1.125rem;
                    line-height: 23px;
                }

                .big {
                    font-size: 1.5rem;
                    line-height: 31px;
                }
            }

            .theme-mode {
                background: #FFFFFF;
                border-top: 1px solid #DDDDDD;
                border-radius: 0px 0px 8px 8px;

                .row {
                    margin: 0;
                }

                .light-mode,
                .dark-mode {
                    font-family: 'Inter';
                    font-size: 0.688rem;
                    line-height: 13px;
                    color: var(--dark-gray);
                    padding: 5.5px 8px;
                    cursor: pointer;

                    span {
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        display: inline-block;
                        margin-right: 4px;
                        vertical-align: middle;
                    }
                }

                .light-mode {
                    background: #DDEAFF;
                    border-radius: 0px 0px 0px 8px;

                    span {
                        background: linear-gradient(90.29deg, #583EE7 26.99%, #0EA0E6 50.09%, #00B6AE 74.69%);
                    }
                }

                .dark-mode {
                    span {
                        background: var(--black);
                    }
                }
            }
        }
    }

    &-invest-btns {
        padding-top: 40px;
        padding-bottom: 46px;

        .btn {
            min-width: 182px;
            padding: 12px 30px;
            margin: 0 8px 10px 8px;
            font-weight: 600;
            font-size: 1.125rem;
            line-height: 150%;
        }
    }

    &-locations {
        padding-top: 35px;
        padding-bottom: 20px;

        .text-big {
            font-weight: 400;
        }

        .location-img-block {
            margin-top: 52px;
            margin-bottom: 52px;
        }
    }

    &-fund-types {
        padding-top: 70px;
        padding-bottom:45px;

        .container {
            max-width: 1310px
        }

        .text-big {
            margin-bottom: 60px;
        }

        .nav-pills {
            background: #fff;
            box-shadow: 0px 4px 60px rgba(16, 27, 66, 0.24);
            border-radius: 8px;
            padding: 16px;

            .nav-link {
                margin-bottom: 30px;
                border-bottom: 1px solid #DDEAFF;
                padding: 8px 8px 16px 8px;
                font-weight: 400;
                font-size: 1.25rem;
                line-height: 150%;
                border-radius: 0;
                text-align: center;
                letter-spacing: -0.03em;
                color: var(--black);

                &.active {
                    background: transparent;
                    color: var(--blue);
                    border-bottom: 4px solid var(--blue);
                    font-weight: 700;
                }
            }
        }

        .dropdown {
            display: inline-block;

            .dropdown-toggle {
                font-family: 'Inter', sans-serif;
                font-weight: 500;
                font-size: 0.875rem;
                line-height: 143%;
                letter-spacing: -0.03em;
                color: var(--blue);
                padding: 0;
                margin-left: 6px;
            }
        }

        .tab-content {
            flex: 1 0 0%;
        }

        .slide:not(:last-child) {
            @media(min-width:768px) {
                margin-bottom: 16px;
            }
        }


    }

    &-financial {
        padding-top: 87px;
        padding-bottom: 47px;

        .tab-content {
            max-width: 1011px;
            margin: 20px auto 44px auto;
            background: linear-gradient(90.29deg, #583EE7 26.99%, #0EA0E6 50.09%, #00B6AE 74.69%);
            box-shadow: 0px 4px 38px rgba(16, 27, 66, 0.2);
            border-radius: 16px;
            padding: 38px 52px;
            color: #fff;

            .tab-content {
                padding: 0;
                color: inherit;
                margin: 0;
                background: transparent;
                box-shadow: none;
                border-radius: 0;
            }

            h3 {
                font-weight: 600;
                margin-bottom: 8px;
            }

            .text-big {
                opacity: 0.7;
                line-height: 142%;
                margin-bottom: 32px;
            }

            .fund-info {
                gap: 30px;
                text-align: center;
                background: #fff;
                border-radius: 16px;
                padding: 24px;
                color: #3C425D;
                width: 383px;
                font-size: 1.125rem;
                font-weight: 500;
                .numbers {
                    gap: inherit;
                }

                h3 {
                    margin-top: 10px;
                    color: var(--black);
                    font-size: 1.75rem;
                    line-height: 101%;
                }

                .btn {
                    padding: 12px 40px;
                    font-family: "Inter";
                    font-size: 0.875rem;
                    font-weight: 500;
                    line-height: 20px;
                    width: 100%;
                }
            }

            .form-group {
                margin-bottom: 32px;

                .form-label {
                    margin-bottom: 12px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-between;
                    font-weight: 500;
                    font-size: 1.125rem;
                    line-height: 158%;


                }

                .custom-range {
                    width: 100%;

                }
            }
        }

        .selected-range {
            border-radius: 5px;
            border: 1px solid #FFF;
            padding: 4px 8px;
            font-size: 1rem;
            font-weight: 600;
            letter-spacing: -0.54px;
            width: 116px;
            display: inline-block;
            text-align: center;
            background: rgba(255, 255, 255, 0.10);
            color: #fff;
            outline: none;
            box-shadow: none;
            transition: all 0.3s ease-in-out;

            &:focus {
                background: #fff;
                color: var(--black)
            }
        }

        .invest-options {
            display: flex;
            align-items: center;
            gap: 12px;

            .form-check {
                padding: 0;
                width: 25%;
                position: relative;
                margin-bottom: 12px;

                .form-check-input {
                    width: 100%;
                    height: 100%;
                    margin-left: 0;
                    margin: 0;
                    position: absolute;
                    opacity: 0;
                }

                .form-check-label {
                    margin: 0;
                    width: 100%;
                    font-size: 0.875rem;
                    font-weight: 500;
                    letter-spacing: -0.42px;
                    border-radius: 61px;
                    border: 1px solid #FFF;
                    padding: 7px 16px;
                    color: #fff;
                    text-align: center;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;

                }

                .form-check-input:checked[type=radio]+.form-check-label {
                    background: var(--blue);

                    &::before {
                        content: "";
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        background: url("/assets/images/fund/tickcircle.png");
                        vertical-align: middle;
                        margin-top: -3px;
                        margin-right: 2px;

                    }
                }
            }
        }

        .graph-table-btns {
            border-radius: 8px;

            .btn {
                border-radius: 8px;
                color: #8189A2;

                &.active {
                    color: #fff
                }
            }
        }
    }

    &-solutions {
        padding-top: 58px;
        padding-bottom: 38px;

        .carousel {
            margin-top: 44px;
            margin-bottom: 26px;
        }
    }

    &-guide {
        padding-top: 58px;
        padding-bottom: 38px;

        .container {
            max-width: 1180px
        }

        .nav-tabs {
            margin-top: 68px;
            margin-bottom: 60px;
        }

        .guide-box {
            background: #FFFFFF;
            box-shadow: 0px 4px 60px rgba(16, 27, 66, 0.24);
            border-radius: 16px;
            padding: 32px 30px;

            h3 {
                color: #595A6C;
                margin-bottom: 28px;
            }

            .blog-box {
                position: relative;
                margin: 0 auto;
                margin-bottom: 20px;
                width: calc(100% - 20px);
                transition: all 0.3s ease-in-out;

                &:hover {
                    // width: 100%
                }

                img {
                    border-radius: 8px;
                    width: 100%;
                }

                .blog-info {
                    position: absolute;
                    bottom: 24px;
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 25px;

                    .title {
                        font-family: 'Inter', sans-serif;
                        font-weight: 600;
                        font-size: 1rem;
                        line-height: 19px;
                        letter-spacing: -0.03em;
                        color: #fff;

                        small {
                            display: block;
                            font-weight: 400;
                            font-size: 0.813rem;
                            line-height: 16px;
                            margin-bottom: 8px;
                            .dot{
                                margin-right: 8px;
                                margin-left: 8px;
                                display: inline-block;
                                width: 4px;
                                height: 4px;
                                background: #fff;
                                border-radius: 50%;
                                vertical-align: middle;
                            }
                        }

                        .rating {
                            margin-bottom: 14px;
                            color: #E9ED31;
                            font-size: 1.313rem;
                        }
                    }

                    .btn {
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-wrap: wrap;
                        padding: 0;

                        i {
                            line-height: 0;
                            font-size: 1.563rem;
                        }
                    }
                }

            }

            .more-links {
                font-weight: 500;
                letter-spacing: -0.03em;
            }
        }
    }

    &-digitalize {
        padding-top: 58px;
        padding-bottom: 42px;

        .carousel {
            margin-top: 50px;
            margin-bottom: 50px;
        }
    }

    &-latest-updates {
        padding-top: 90px;
        padding-bottom: 50px;
        background-color: #FAFBFF;

        .text-big {
            margin-bottom: 60px;
        }

        .container {
            max-width: 1100px
        }
    }

    &-faq {
        padding-top: 70px;
        padding-bottom: 40px;

        .container {
            max-width: 1230px;
        }

        .faq-box {
            background: #fff;
            box-shadow: 0px 4px 60px rgba(16, 27, 66, 0.24);
            border-radius: 16px;
            padding: 40px 52px;
            margin-top: 56px;
            margin-bottom: 60px;

            .accordion {

                .accordion-item {
                    border: 0;
                    border-bottom: 1px solid #DEE4F780;
                    padding-bottom: 24px;
                    margin-bottom: 24px;

                    background: transparent;

                    &:last-child {
                        margin-bottom: 0;
                        padding-bottom: 0;
                        border: 0
                    }

                    .accordion-header {
                        .accordion-button {
                            background: transparent;
                            padding: 0;
                            font-weight: 500;
                            font-size: 1.25rem;
                            line-height: 156%;
                            color: #101828;
                            border: 0;
                            box-shadow: none !important;
                            outline: none !important;

                            &:not(.collapsed) {
                                color: #101828;
                                background-color: transparent;
                                box-shadow: none !important;
                            }

                            &::after {
                                flex-shrink: 0;
                                width: 1.25rem;
                                height: 1.25rem;
                                margin-left: auto;
                                content: "";
                                background-image: url("/assets/images/home/plus-icon.svg");
                                background-repeat: no-repeat;
                                background-size: 1.25rem;
                                transition: transform .2s ease-in-out;
                            }

                            &:not(.collapsed)::after {
                                background-image: url("/assets/images/home/minus-icon.svg");
                            }
                        }
                    }

                    .accordion-body {
                        padding: 0;
                        color: #667085;
                        padding-top: 8px;
                    }
                }
            }
        }
    }

    &-invest-form {
        padding-top: 100px;
        padding-bottom: 242px;
        background-color: #FAFBFF;

        .form-box {
            padding: 24px 32px;
            background: #FFFFFF;
            box-shadow: 0px 4px 76px rgba(23, 55, 163, 0.08);
            border-radius: 8px;
            max-width: 870px;
            margin: 0 auto;

            h3 {
                font-weight: 700;
                margin-bottom: 36px;
            }

            form {
                font-family: 'Inter', sans-serif;
                .form-control, .form-select{

                    padding: 10px 14px;
                    font-size: 1rem;
                }
            }

            .btn {
                width: 100%;
                padding: 14px;
                font-weight: 500;
            }
        }
    }

    &-download-options {
        background: #DDEAFF;

        .container {
            position: relative;
            max-width: 1025px;
        }

        .download-img {
            margin-top: -130px;
            margin-right: -20px;
        }

        h2 {
            font-size: 2.5rem;
            line-height: normal;
            max-width: 537px;
            margin-bottom: 36px;
            .text-gradient{
                text-transform: none;
            }
        }


    }
}

.fix-mobile-margin {
    // margin-top: calc(18rem - 2px)!important;
}
.footer-links-CTA {
    background: transparent!important;
    -webkit-background-clip: unset!important;
    -webkit-text-fill-color: white!important;
}
.fix-margin {
    // margin-top: calc(15rem - 2px)!important;
}
.margin-top-10 {
    margin-top: 8rem;
}
