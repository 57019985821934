.FT {
	// &-calculators-links {
	//     padding-top: 46px;
	//     padding-bottom: 40px;

	//     .container {
	//         max-width: 1285px;
	//     }

	//     .head {
	//         max-width: 1134px;
	//         margin-bottom: 54px;

	//         h2 {
	//             color: var(--blue);
	//             font-size: 2rem;
	//             font-style: normal;
	//             font-weight: 700;
	//             line-height: normal;
	//             letter-spacing: -0.96px;
	//             margin-bottom: 12px;
	//         }

	//         .text-big {
	//             color: #191D23;
	//             font-size: 1.125rem;
	//             font-style: normal;
	//             font-weight: 700;
	//             line-height: 133.333%;
	//         }
	//     }


	// }

	// &-formula {
	//     .container {
	//         max-width: 1285px;
	//     }

	//     .formula-box {
	//         border-radius: 8px;
	//         border: 1px solid #F2F4F7;
	//         background: #fff;
	//         box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
	//         padding: 24px;
	//         margin-bottom: 40px;
	//         font-size: 1.125rem;
	//         font-weight: 700;
	//         line-height: 133.333%;

	//         h3 {
	//             font-size: 1.5rem;
	//             font-weight: 700;
	//             letter-spacing: -0.72px;
	//             margin-bottom: 20px;
	//         }

	//         &.font-inter {
	//             font-size: 1rem;
	//         }
	//         ul{
	//             list-style: decimal;
	// padding-left: 18px;
	//             li{
	//                 line-height: 160%;
	//                 margin-bottom: 8px;
	//             }
	//         }
	//     }
	// }

	&-calc-faq {
		padding-top: 0;
		padding-bottom: 64px;

		.container {
			max-width: 1349px;
		}

		h3 {
			font-size: 1.75rem;
			font-weight: 700;
			letter-spacing: -0.84px;
			margin-bottom: 24px;

		}

		.faq-box {
			margin: 0;
			padding: 42px 52px;
			box-shadow: none;

			.accordion .accordion-item {
				.accordion-header .accordion-button {
					font-size: 1.125rem;
				}

				.accordion-body {}
			}
		}
	}

	&-calculator-accordion {
		.container {
			max-width: 1349px
		}

		padding-bottom: 64px;

		.accordion {
			.accordion-item {
				border: 0;
				background: transparent;

				.accordion-header {
					button {
						padding: 16px;
						color: #3C425D;
						font-family: "Inter";
						font-weight: 700;
						line-height: 24px;
						outline: none;
						box-shadow: none;
						border-radius: 8px;
						background: #fff;

						img {
							margin-right: 8px;
						}
					}
				}

				.accordion-collapse {
					.accordion-body {
						border-radius: 8px;
						border: 1px solid #F2F4F7;
						background: #fff;
						box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
						margin-top: 8px;
						padding: 24px;
						color: #3C425D;
						font-family: "Inter";
						font-size: 1rem;
						font-style: normal;
						font-weight: 400;
						line-height: 26px;

						a {
							text-decoration: underline;
							color: #3C425D;
						}

						ul {
							padding-left: 18px;
							list-style-type: decimal;

							&.list-with-img {
								list-style: none;
								list-style-type: none;
								padding: 0;

								li {
									padding: 12px;
									padding-left: 31px;
									position: relative;
									font-family: "Inter";
									font-size: 1.125rem;
									line-height: 133.333%;

									&::before {
										content: "";
										display: block;
										width: 23px;
										height: 23px;
										position: absolute;
										top: 12px;
										left: 0;
										background: url("/assets/images/calculators/tick-circle.svg") no-repeat center center;
										background-size: 100% 100%;
									}
								}
							}
						}

						&.accordion-slider {
							padding: 0;
							background: transparent;
							box-shadow: none;

							.slick-slide {
								padding: 0 8px;
							}

							.benefits-box {
								border-radius: 9px;
								background: #FFF;
								padding: 24px;
								height: 100%;

								.text-big {
									color: #101828;
								}

								.text-small {
									color: #667085;
									font-family: "Inter";
									line-height: 161.538%;
								}
							}
						}
					}
				}
			}
		}
	}
}
