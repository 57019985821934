/*** form styles **/
form {
    .form-group {
        position: relative;

    }

    .form-control,
    .form-select,
    .date-group {
        border: 0.776786px solid #D2D8EA;
        box-shadow: 0px 0.776786px 1.55357px rgba(16, 24, 40, 0.05) !important;
        border-radius: 6.21429px;
        height: 45px;
        padding: 13px 16px;
        font-size: 0.875rem;
        outline: none !important;

        &:focus {
            border-color: var(--blue);
        }

    }

    textarea.form-control {
        height: 80px
    }

    .form-check {
        .form-check-label {
            color: var(--dark-gray);

            a {
                text-decoration: underline;
                color: var(--blue);
            }
        }

    }

    .error {
        .form-control {
            border-color: #F04438;
        }

        i {
            position: absolute;
            right: 14px;
            top: 11px;
            color: #F04438;
        }

        .error-msg {
            color: #F04438;
            font-family: "Inter";
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }

        &.col-6,
        &.col-12 {
            i {
                right: 22px;
            }

        }
    }
}

.date-group {
    display: flex;
    align-items: center;
    position: relative;

    .form-control {
        padding: 0;
        height: 100%;
        border: 0;
        box-shadow: none;
    }

    input[type="date"i] {
        &::-webkit-calendar-picker-indicator {
            position: absolute;
            width: 100%;
            background: transparent;
        }
    }
}

.range-group {


    .form-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .selected-range{
            border-radius: 4px;
            border: 1px solid #8189A2;
            padding:6px 24px;
            text-align: center;
            input{
                padding:0;
                border:0 !important;
                outline: none !important;
                box-shadow: none !important;
                width: auto;
                min-width: 0;
                max-width: fit-content;
            }
        }

    }

    /* Chrome, Safari, Edge, Opera */
    .calc-input::-webkit-outer-spin-button,
    .calc-input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    .calc-input[type=number] {
        -moz-appearance: textfield;
    }

    input {
        background: transparent;
    }
}


.custom-check{
    &.form-check {
        display: inline-block;
        margin: 0 16px 0 0;
        padding: 0;

        .form-check-label {
            padding: 8px 16px;
            border-radius: 32px;
            background: #fff;
            border: 1px solid var(--blue);
            color: var(--blue);
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 100% ;
            cursor: pointer;
        }

        .form-check-input {
            width: 100%;
            height: 100%;
            opacity: 0;
            visibility: hidden;
        }

        .form-check-input:checked[type=checkbox]+.form-check-label,.form-check-input:checked[type=radio]+.form-check-label {
            background: var(--blue);
            color: #fff;
        }
    }
}
.group-info{
    display: flex;
    align-items: center;
    .form-control{
        flex:1 0 0
    }
    .info-icon{
        margin-left: 8px;
        color: #8189A2;
        cursor: pointer;
    }
}
