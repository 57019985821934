@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&family=Hanken+Grotesk:wght@400;500;600;700;800;900&family=Inter:wght@300;400;500;600;700;800&display=swap");
@import "./assets/scss/main.scss";

body {
  overflow-x: hidden;
  &.hideHeader {
    overflow: hidden;
  }
}
.hideHeader {
  ft-header, ft-breadcrumb,ft-fund-header,ft-fund-tabs,ft-main-fund-content,ft-fund-performance-entry,
  ft-sector-breakdown,ft-fund-documents,ft-fund-nav, .scheme-details, .block-head, ft-footer, 
  ft-idcw-history, ft-fund-managers .block-body.te-td-error,  ft-fund-managers .block-body.te-td-border, .no-navs {
      display: none;
  }

  ft-legal-caveats {
    .text-small > p:not(.nav-calculator-notes) {
      display: none;
    }
  }

  .FT-fund-more-details.block-border {
    border: 0px!important;
    margin-bottom: 0px;
  }
  .container {
    padding: 0px;
  }
  .block-head {
    display: none;
  }
  .legal-caveats {
    border: 0px;
    border-top: 1px solid #D2D8EA;
    border-radius: 0px;
    margin-top: 0px!important;
  }
}

// --> Handle on Large font size
// mobile view;
@media(max-width: 576px) {
  html.large {
    .header-notice {
      height: 280px !important;
    }

    .FT-top-spacing {
      margin-top: 394px;
    }

    .mobile-fix-top {
      top: -346px !important;
    }
  }

}

@media (max-width: 768px) {
  .sx-black-color {
    color: #000;
  }
}

// global font resize
.fontResize {
  transition: font 0.2s ease-in-out !important;
}

html.small {
  font-size: 14px !important;
}


html.large {
  font-size: 18px !important;
}


html.large .header-notice {
  height: 165px;
}
