/**
 Bloomreach Edit Mode
 ------------------------------------------------------------
**/

.has-edit-button {
  display: inline;
}

.no-document {
  margin-bottom: var(--space-xxxl);

  &__config {
    text-align: center;
    display: flex;
  }

  &__component {
    align-items: center;
    background: var(--greyscale-5);
    border: 1px dashed var(--greyscale-3);
    display: flex;
    height: 200px;
    text-align: center;
  }

  &__overlay {
    margin: auto;
  }

  strong {
    font-weight: 700;
  }
}

#loader img {
  width: 100px;
  height: 100px;
}
